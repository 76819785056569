// @ts-nocheck
import { Box, HStack } from "@chakra-ui/react";
import { ShowProps } from "@chakra-ui/media-query";
import dynamic from "next/dynamic";
import { ComponentType, useEffect, useState } from "react";
import Container from "../Container";
import { useAccount } from "wagmi";
import { CandidateProfile } from "../../../core/models/candidate";
import usersService from "../../services/UserService";
import { useRouter } from "next/router";
import { useModalContext } from "hooks/useContext";
import AuthUser from "types/AuthUser";
import useUserDetails from "hooks/useUserDetails";
import Link from "../Link";

const Show = dynamic(
  () => import("@chakra-ui/media-query").then((mod) => mod.Show) as any,
  {
    ssr: false,
  }
) as ComponentType<ShowProps>;

interface HeaderStripProps { }

const HeaderStrip: React.FC<HeaderStripProps> = (props) => {

  const router = useRouter();
  const { user: currentUser } = useUserDetails();
  const [myType, setMyType] = useState(0);
  const [candidateProfile, setCandidateProfile] = useState<CandidateProfile>();
  const { address } = useAccount();
  const { verifyAdharOTPModal } = useModalContext();

  const KYC_BANNER_MESSAGE = process.env.NEXT_PUBLIC_KYC_BANNER;

  useEffect(() => {

    const loadWalletData = async () => {

      if (currentUser && currentUser?.userId && currentUser?.userId > 0) {
        if (!candidateProfile || (candidateProfile && !candidateProfile?.publicProfile?.isKyc)) {
          let authDetail = currentUser as AuthUser;
          if (authDetail.isRegistered && (authDetail.roleId == 2 || authDetail.roleId == 1)) {
            setMyType(authDetail.roleId);

            if (authDetail.roleId == 2) {

              const dataModel = {
                emaildId: authDetail?.emailId,
              }

              let resp = await usersService.GetUserFilteredProfileByWalletAddress('token', dataModel);
              Promise.any([resp]).then(async (data: any) => {

                if (data && data?.status == 200) {
                  let candidate = data.data as CandidateProfile;
                  setCandidateProfile(candidate);
                }
              })
            }
          }
        }
      }
      else {
        setMyType(-1);
      }
    }
    loadWalletData();
  }, [currentUser, router.asPath, verifyAdharOTPModal.isOpen == false]);

  return (
    candidateProfile && myType == 2 && !candidateProfile?.publicProfile?.isKyc ? (
      <Box bg="#0AB1BC" as="div" pos="relative">
        <Container py="1" px="1" >
          <HStack w="full" >
            <HStack
              w="inherit"
              align={"flex-end"}
              justifyContent="center" alignItems={"center"}>
              <Show above="lg">
                {
                  // @ts-ignore - "Does not exist" Type Error against Marquee
                  <Box
                    as="marquee"
                    fontSize={"1xl"} fontWeight="semibold" color={"white"}
                    onMouseOver={(e) => e.currentTarget.stop()}
                    onMouseOut={(e) => e.currentTarget.start()}
                    onClick={(e) => {
                      e.preventDefault();
                      router.push("/kyc");
                    }}
                  >
                    {KYC_BANNER_MESSAGE ?? "You do not seem to have done your KYC. KYC is mandatory to avail all the benefits. Please complete your KYC process as soon as possible."}
                    <Link
                      color="blackAlpha.900"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push("/kyc");
                      }}
                      onMouseOver={(e) => e.currentTarget.parentNode?.stop()}
                      onMouseOut={(e) => e.currentTarget.parentNode?.start()}
                    >
                      {" Click here to goto KYC page"}
                    </Link>
                  </Box>
                }
              </Show>
            </HStack>
          </HStack>
        </Container>
      </Box>
    ) : (
      <div />
    )
  );
};
export default HeaderStrip;
