import { Menu, MenuButton, MenuList } from "@chakra-ui/menu";
import { Button, Circle, HStack, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { IoNotificationsCircle } from "react-icons/io5";
import { CandidateProfile } from "../../../core/models/candidate";
import { NotificationRequest } from "../../../core/models/notification/NotificationRequest";
import { OrgProfile } from "../../../core/models/org";
import NotificationList from "../notification";

import { TabPanel, Tabs, TabPanels } from "../tab-nav";
import { Body2, Caption3 } from "../typography";

interface NotificationMenuProps { }

const NotificationMenu: FC<NotificationMenuProps> = (props) => {
  const [organizationProfile, setOrganizationProfile] = useState<OrgProfile>();
  const [candidateProfile, setCandidateProfile] = useState<CandidateProfile>();
  const [notificationRequest, setNotificationRequest] = useState<NotificationRequest[] | null>([]);
  const [autoUpdated, setAutoUpdated] = useState(false);
  const myType = 0;
  
  // useEffect(() => {

  //   if (mySelf && myType) {
  //     if (myType == 1) {
  //       var org = mySelf as OrgProfile;
  //       setOrganizationProfile(org);
  //     }
  //     else {
  //       var cand = mySelf as CandidateProfile;
  //       setCandidateProfile(cand);
  //     }
  //     const loadCanDisData = async () => {

  //       let allNotifications: NotificationRequest[] = [];

  //       var dispreqs = await getCandidateDisputeReqs(myType == 1 ? organizationProfile?.companyid ?? "" : candidateProfile?.privateProfile?.panNumber ?? "");

  //       const orgEmpReqs = await getOrgcandidateDetailReqs(myType == 1 ? mySelf?.companyid : mySelf?.privateProfile?.panNumber);

  //       if (dispreqs && dispreqs.length > 0) {

  //         dispreqs.forEach((d, i) => {
  //           if (myType == 1) {
  //             var isRead = false;
  //             if (i > 4) {
  //               isRead = true;
  //             }

  //             allNotifications.push({
  //               candidateProfile: d.publicProfile,
  //               orgProfile: {
  //                 compid_hash: myType == 1 ? organizationProfile?.companyid ?? "" : d.experience.companyid?.toString(),
  //                 compName: myType == 1 ? organizationProfile?.name ?? "" : d.experience.orgName?.toString(),
  //                 compPic: myType == 1 ? organizationProfile?.profilePic ?? "" : "/images/edu2.png",
  //               },
  //               notificationRequestDetails: {
  //                 ntfId: d.disputeReqDetails.disputeId,
  //                 ntfName: "Dispute",
  //                 ntfDate: d.disputeReqDetails.disputeDate,
  //                 isRead: isRead,
  //                 idSenderEmp: myType == 1 ? true : false,
  //               },
  //               unique_link: d.unique_link,
  //               item_hash: d.item_hash,
  //               item_ref: d.item_ref,
  //             });
  //           }
  //           else if (myType == 2 && d.experience.dispute && !d.disputeReqDetails.disputeResolvedStatus &&d.disputeChatDetails[d.disputeChatDetails.length - 1].dispute_status == 0) {
  //             var isRead = false;
  //             if (i > 4) {
  //               isRead = true;
  //             }

  //             allNotifications.push({
  //               candidateProfile: d.publicProfile,
  //               orgProfile: {
  //                 compid_hash: d.experience.companyid?.toString(),
  //                 compName: d.experience.orgName?.toString(),
  //                 compPic: "/images/edu2.png",
  //               },
  //               notificationRequestDetails: {
  //                 ntfId: d.disputeReqDetails.disputeId,
  //                 ntfName: "Dispute",
  //                 ntfDate: d.disputeReqDetails.disputeDate,
  //                 isRead: isRead,
  //                 idSenderEmp: false,
  //               },
  //               unique_link: d.unique_link,
  //               item_hash: d.item_hash,
  //               item_ref: d.item_ref,
  //             });
  //           }

  //         });

  //       }

  //       if (orgEmpReqs && orgEmpReqs.length > 0) {

  //         orgEmpReqs.forEach((d, i) => {

  //           if (myType == 1 && (d?.candidateRequestDetails?.idDetailStatus == 1 || d?.candidateRequestDetails?.personalDetailStatus == 1)) {
  //             var isRead = false;
  //             if (i > 4) {
  //               isRead = true;
  //             }

  //             allNotifications.push({
  //               candidateProfile: d.publicProfile,
  //               orgProfile: {
  //                 compid_hash: d.candidateRequestDetails.compid_hash,
  //                 compName: d.candidateRequestDetails.compName,
  //                 compPic: d.candidateRequestDetails.compPic,
  //               },
  //               notificationRequestDetails: {
  //                 ntfId: d.candidateRequestDetails.reqId,
  //                 ntfName: "Profile View Request",
  //                 ntfDate: d.candidateRequestDetails.reqDate,
  //                 isRead: isRead,
  //                 idSenderEmp: myType == 1 ? true : false,
  //               },
  //               unique_link: d.unique_link,
  //               item_hash: d.item_hash,
  //               item_ref: d.item_ref,
  //             });
  //           }
  //           else if (myType == 2 && (d?.candidateRequestDetails?.idDetailStatus == 0 || d?.candidateRequestDetails?.personalDetailStatus == 0)) {
  //             var isRead = false;
  //             if (i > 4) {
  //               isRead = true;
  //             }

  //             allNotifications.push({
  //               candidateProfile: d.publicProfile,
  //               orgProfile: {
  //                 compid_hash: d.candidateRequestDetails.compid_hash,
  //                 compName: d.candidateRequestDetails.compName,
  //                 compPic: d.candidateRequestDetails.compPic,
  //               },
  //               notificationRequestDetails: {
  //                 ntfId: d.candidateRequestDetails.reqId,
  //                 ntfName: "Profile View Request",
  //                 ntfDate: d.candidateRequestDetails.reqDate,
  //                 isRead: isRead,
  //                 idSenderEmp: false,
  //               },
  //               unique_link: d.unique_link,
  //               item_hash: d.item_hash,
  //               item_ref: d.item_ref,
  //             });
  //           }


  //         });

  //       }
        
  //       var sorted_notification = allNotifications.sort((a, b) => {
  //         return new Date(a?.notificationRequestDetails?.ntfDate).getTime() -
  //           new Date(b?.notificationRequestDetails?.ntfDate).getTime()
  //       }).reverse();
        
  //       if (!_.isEqual(sorted_notification, notificationRequest)) {
  //         setNotificationRequest(sorted_notification);
  //       }

  //       setAutoUpdated(x => !x);

  //     }
  //     loadCanDisData();
  //   }
  // }, [account, contracts?.nestedReadContract, myType, mySelf, autoUpdated]);

  return (
    <Menu placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            pos="relative"
            rounded="full"
            bg={isOpen ? "secondary" : "transparent"}
          >
            <IoNotificationsCircle color="white" size={30} />
            <Circle
              fontSize="8px"
              fontWeight="600"
              color="white"
              size="14.5px"
              bg="red"
              top="-2px"
              border="1px solid #264252"
              right="-2px"
              pos="absolute"
            >
              {notificationRequest?.length}
            </Circle>
          </MenuButton>
          <MenuList
            zIndex="100"
            p="4"
            rounded="xl"
            boxShadow="2xl"
            h="396px"
            w="350px"
          >
            <VStack spacing={3} align="stretch">
              <HStack justify="space-between">
                <Body2 fontWeight="600">Notifications</Body2>
                <Button variant="link">
                  <Caption3 fontWeight="600" color="secondary">
                    Mark All As Read
                  </Caption3>
                </Button>
              </HStack>
              <Tabs
                tabList={["All", "Unread"]}
                tabProps={{
                  py: 1,
                  px: 3,
                }}
                fontSize="10px"
              >
                <TabPanels>
                  <TabPanel mt="3">
                    <NotificationList notifications={notificationRequest} myType={myType} onClose={onClose}/>
                  </TabPanel>
                  <TabPanel mt="3">
                    <NotificationList myType={myType} onClose={onClose}
                      notifications={notificationRequest?.filter(
                        (notification) => !notification.notificationRequestDetails.isRead
                      )}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default NotificationMenu;
