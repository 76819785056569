import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { HStack, Icon, Tooltip, useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useDisconnect, useAccount } from "wagmi";

import { FC, useEffect, useState } from "react";
import { IoChevronDownOutline, IoPersonCircle, IoCopy } from "react-icons/io5";
import { CandidateProfile } from "../../../core/models/candidate";

import { Caption2 } from "../typography";
import useUser from "hooks/useUser";
import { useModalContext } from "hooks/useContext";
import ChangeProfilePasswordModal from "../modal/ChangeProfilePasswordModal";
import useUserDetails from "hooks/useUserDetails";
import orgService from "@/components/services/OrgService";
import { OrgProfile } from "@/core/models/org";
import { encryptToHex } from "@/core/utility/common";

interface UserMenuProps {
  myType?: number;
  isRegistered?: boolean;
  candidateProfile?: CandidateProfile;
}

const UserMenu: FC<UserMenuProps> = (props) => {
  const { myType, candidateProfile, isRegistered } = props;
  const router = useRouter();
  const [checkedIsRecruiter, setCheckedIsRecruiter] = useState(false);
  const [isDefaultPassword, setIsDefaultPassword] = useState(false);
  const [isClosable, setIsClosable] = useState(true);
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const toast = useToast();
  const { mutateUser } = useUser();
  const { changeProfilePasswordModal } = useModalContext();
  const { onOpen, onClose } = changeProfilePasswordModal;
  const { user: currentUser } = useUserDetails();
  const nestedRoles = [1, 2, 3, 4, 5];

  const NESTED_PROFILE_DEFAULT_PASS = process.env.NEXT_PUBLIC_NESTED_PROFILE_DEFAULT_PASS || "se6r2GA";

  useEffect(() => {

    if (candidateProfile && !candidateProfile?.publicProfile?.isFresher && candidateProfile?.experience?.length > 0) {
      var isCandidateRecruiter = candidateProfile.experience.filter(x => x.isRecruiter);
      if (isCandidateRecruiter && isCandidateRecruiter.length > 0) {
        setCheckedIsRecruiter(true);
      }
      else {
        setCheckedIsRecruiter(false);
      }
    }
  }, [candidateProfile]);



  useEffect(() => {
    const loadOrganizationData = async () => {

      const roleType = currentUser?.roleId ?? 0;
      if (currentUser && currentUser?.userId && currentUser?.userId > 0 && roleType == 1) {
        const dataModel = {
          emailId: currentUser?.emailId,
          companyId: '',
        }

        const resp = await orgService.GetOrgByWalletAddress('token', dataModel);
        if (resp && resp?.status == 200) {
          const defaultPassword = await encryptToHex(NESTED_PROFILE_DEFAULT_PASS) ?? "";//default password
          const organization = resp.data as OrgProfile;
          if (organization && organization.password === defaultPassword) {
            setIsDefaultPassword(true);
            setIsClosable(false);
            onOpen();
          }else{
            setIsDefaultPassword(false);
            setIsClosable(true);
          }
        }
      }
    }
    loadOrganizationData();
  }, [currentUser, myType]);

  const handleLogout = async () => {
    const resp = await fetch('/api/logout');
    const userData = await resp.json();
    mutateUser(userData);
    disconnect();
    router.push("/");
  };

  const handleAccountSetting = () => {
    router.push("/account/settings/employee");
  };

  const handleRecruiterAccount = () => {
    router.push("/profile/recruiter");
  };

  const copyToClipBoard = async (copyMe?: string) => {
    try {
      if (copyMe) {
        await navigator.clipboard.writeText(copyMe);
        toast({
          title: "Copied to Clipboard!",
          status: "success",
          duration: 3000,
          position: 'bottom-right',
          variant: 'left-accent',
          isClosable: true
        });
      }
      else {
        toast({
          title: "Wallet address not found!",
          status: "warning",
          duration: 3000,
          position: 'bottom-right',
          variant: 'left-accent',
          isClosable: true
        });
      }

    } catch (err) {
      toast({
        title: "Unable to copy!",
        status: "error",
        duration: 3000,
        position: 'bottom-right',
        variant: 'left-accent',
        isClosable: true
      });
    }
  };

  return (
    <>
      <ChangeProfilePasswordModal isDefaultPassword={isDefaultPassword} isClosable={isClosable} />
      <Menu placement="bottom-end">
        {({ isOpen }) => (
          <>
            <MenuButton>
              <HStack spacing={0} mr="10px">
                <Icon
                  rounded="full"
                  as={IoPersonCircle}
                  boxSize="30px"
                  color="white"
                  bg={isOpen ? "secondary" : "transparent"}
                />
                <IoChevronDownOutline color="lightgray" size={16} />
              </HStack>
            </MenuButton>
            <MenuList
              overflow="hidden"
              rounded="2xl"
              minW="160px"
              p="0"
              boxShadow="2xl"
            >
              <MenuItem display={"none"} p="3" onClick={(e) => {
                e.preventDefault();
                copyToClipBoard(address)
              }}>
                <Caption2 fontWeight="600" color="black">
                  {address?.substring(0, 4)}....{address?.substring(address.length - 8)}
                  <Icon as={IoCopy} />
                </Caption2>
              </MenuItem>
              <MenuItem display={"none"} p="3" onClick={(e) => {
                e.preventDefault();
                router.push("/profile/employee");
              }}>
                <Caption2 fontWeight="600" color="black">
                  My Profile
                </Caption2>
              </MenuItem>
              <MenuItem display={myType && myType == 2 && isRegistered ? "block" : "none"} p="3" onClick={(e) => {
                e.preventDefault();
                router.push("/referral/employee");
              }}>
                <Caption2 fontWeight="600" color="black">
                  My Referral
                </Caption2>
              </MenuItem>
              <MenuItem display={myType && myType == 2 && isRegistered ? "block" : "none"} p="3" onClick={(e) => {
                e.preventDefault();
                router.push("/kyc");
              }}>
                <Caption2 fontWeight="600" color="black">
                  KYC
                </Caption2>
              </MenuItem>
              <MenuItem display={myType && nestedRoles.includes(myType) && isRegistered ? "block" : "none"} p="3" onClick={(e) => {
                e.preventDefault();
                onOpen();
              }}>
                <Caption2 fontWeight="600" color="black">
                  Change Password
                </Caption2>
              </MenuItem>
              <MenuItem display={myType && myType == 2 && checkedIsRecruiter ? "block" : "none"} p="3" onClick={handleRecruiterAccount}>
                <Caption2 fontWeight="600" color="black">
                  Recruiter Settings
                </Caption2>
              </MenuItem>
              <MenuItem p="3" onClick={handleAccountSetting} display={myType && myType === 2 && isRegistered ? "block" : "none"}>
                <Caption2 fontWeight="600" color="black">
                  Account Settings
                </Caption2>
              </MenuItem>
              <MenuDivider my="0" />
              <MenuItem onClick={handleLogout} p="3.5" display="none">
                <Caption2 fontWeight="600" color="black">
                  Log Out
                </Caption2>
              </MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </>

  );
};
export default UserMenu;
