import {
  Modal as _Modal,
  ModalProps as _ModalProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalBodyProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalFooterProps,
} from "@chakra-ui/modal";
import { FC, ReactElement, ReactNode } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalProps?: Partial<_ModalProps>;
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps;
  headerProps?: ModalHeaderProps;
  footerProps?: ModalFooterProps;
  header?: ReactNode;
  footer?: ReactElement;
  children?: any;
}

const Modal: FC<ModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    bodyProps,
    footer,
    contentProps,
    children,
    footerProps,
    header,
    modalProps,
    headerProps,
  } = props;
  return (
    <_Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      {...modalProps}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent rounded="2xl" m={1} {...contentProps}>
        <ModalCloseButton my="2" _focus={{ border: "none" }} />
        {header && (
          <ModalHeader textAlign="center" {...headerProps}>
            {header}
          </ModalHeader>
        )}
        <ModalBody mt={!header ? "3" : "0"} px={[2, 6]} {...bodyProps}>
          {children}
        </ModalBody>
        <ModalFooter {...footerProps} justifyContent="center">
          {footer}
        </ModalFooter>
      </ModalContent>
    </_Modal>
  );
};
export default Modal;
