import { CSSProperties } from "react";
import Image from "./Image";

interface LogoProps {
  src?: string;
  style?: CSSProperties;
}

const Logo: React.FC<LogoProps> = (props) => {
  const src = props.src;
  return (
    <Image
      src={props.src ? props.src : "/images/nestedlogo.png"}
      alt="Logo"
      maxW={"-webkit-fit-content"}
      width={{
        base: "80px",
        lg: "180px",
      }}
      style={props.style}
    />
  );
};
export default Logo;
