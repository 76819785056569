import { HStack, useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import Link from "../Link";
import OrganizationMenu from "../menu/OrganizationMenu";
import Stack from "../Stack";
import { Caption1 } from "../typography";

interface NavigationProps {
  routes: {
    name: string;
    href: string;
    isMenu: boolean;
    index: number;
    menuName?: string;
    isLink?: boolean;
    dialogComponent?: React.ReactNode
  }[];
  direction?: "row" | "column";
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const { routes, direction = "row" } = props;

  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customRoutes, setCustomRoutes] = useState({} as any as _.Dictionary<{
    name: string;
    href: string;
    isMenu: boolean;
    index: number;
    menuName?: string;
    isLink?: boolean;
    dialogComponent?: React.ReactNode;
  }[]>);

  useEffect(() => {
    const groups = _.groupBy(routes, 'index');
    setCustomRoutes(groups);
  }, [routes]);

  return (
    <Stack direction={direction} as="nav" spacing="5" justify="end" pr="8">
      {
        _.map(customRoutes, function navigationMenu(routeList) {

          if (routeList[0].isMenu === undefined || routeList[0].isMenu === false) {

            return routeList.map((route) => {
              const isActive = router.pathname.includes(route.href);
              return (
                <Link href={route.href} key={route.name}>
                  <Caption1
                    color={isActive ? "white" : "gray"}
                    _hover={{
                      color: "white",
                    }}
                    _focus={{
                      color: "white",
                    }}
                    fontWeight="600"
                  >
                    {route.name}
                  </Caption1>
                </Link>
              );
            })
          }
          else {
            return (
              <OrganizationMenu routes={routeList} />
            )

          }
        })
      }
    </Stack>
  );
};
export default Navigation;
