import { Organization } from "@prisma/client";
import { ContactDetails, OrgProfile } from "../../models/org";

export const convertToOrgProfile = async (result: Organization) => {
    try {

        const organizationProfile = {
            id: result.Id,
            name: result.Name,
            companyid: result.CompanyId,
            mobileNumber: result.MobileNumber,
            website: result.Website,
            email: result.Email,
            about: result.About,
            password: result.Password,
            contact: {
                personName: result.ContactPersonName,
                phoneNumber: result.ContactPersonNo,
                address: result.ContactPersonAddress,
            } as ContactDetails,
            profilePic: result.ProfilePic,
            CreatedDate: result.CreatedDate,
            WalletAddress: result.WalletAddress,
            Reference: result.Reference,
            RoleId: result.RoleId,
            orgKey: result.WalletAddress,
            UpdatedDate: result.UpdatedDate,
            country: result.Country, // ToDo: Need to update
            BlockchainTransactionRef: result.BlockchainTransactionRef
        } as OrgProfile;

        return organizationProfile;
    } catch (error) {
        return null;
    }
}

export const convertToOrganization = async (values: OrgProfile, walletAddress: string, ref: string, blockchainTransactionHash: string) => {
    try {
        const org = {
            Name: values.name,
            CompanyId: values.companyid,
            MobileNumber: values.mobileNumber,
            ProfilePic: values.profilePic,
            Website: values.website,
            Email: values.email,
            Password: values.password,
            About: values.about,
            ContactPersonName: values.contact.personName,
            ContactPersonNo: values.contact.phoneNumber,
            ContactPersonAddress: values.contact.address,
            WalletAddress: walletAddress,
            Reference: ref ?? values.Reference,
            BlockchainTransactionRef: blockchainTransactionHash,
            RoleId: values.RoleId,
            CreatedDate: values.CreatedDate,
            UpdatedDate: new Date(),
            Country: values.country
        } as Organization;
        return org;
    } catch (error) {
        return null;
    }
}
