import * as Yup from "yup";

/**
 * Defines the Yup validation schema for the Change Password form.
 */
export const changePasswordValidation = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(5, 'Password must be at least 5 characters long.'),
    confirmPassword: Yup.string().when('password', (password, field) => password ? field.required('Please retype your password.')
        .oneOf([Yup.ref('password')], 'Your password do not match.') : field
    ),
});

/**
 * Defines the Yup validation schema for the Add Pay form.
 */
export const addPayValidation = Yup.object().shape({
    basePay: Yup.string()
        .required("Please enter extra pay"),
    payPeriod: Yup.string()
        .required("Please enter pay period"),
});

/**
 * Defines the Yup validation schema for the Work Schedule form.
 */
export const workScheduleValidation = Yup.object().shape({
    days: Yup.array()
        .required("Please select at least one day"),
    shifts: Yup.array()
        .required("Please select at least one shift"),
});