import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
//import Modal from "../modal";

interface NoteBoxBoxProps {
    onSuccessAction: () => void;
    headerText: string;
    bodyText: string;
    buttonText: string;
    colorScheme?: string;
    isOpen: boolean;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
}


const NoteBox: FC<NoteBoxBoxProps> = (props) => {
    const { onSuccessAction, buttonText, headerText, bodyText, colorScheme, isOpen, setIsOpen } = props;
    const cancelRef = useRef(null);
    const [bgColor, setBgColor] = useState('secondary');

    const onClose = () => {
        setIsOpen?.(false);
    }

    const onSubmit = () => {
        onSuccessAction();
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent w="max-content" zIndex={99999} rounded="xl" m={1}>
                <ModalHeader textAlign="center">{headerText}</ModalHeader>
                <ModalCloseButton my="2" _focus={{ border: "none" }}/>
                <ModalBody mt={"0"} px={[2, 6]}>
                    <Box whiteSpace={"pre-line"}>{bodyText}</Box>
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button variant="ghost" onClick={onClose} mr={3}>
                        Close
                    </Button>
                    <Button background={colorScheme ?? bgColor} onClick={onSubmit}>
                        {buttonText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default NoteBox;
