import { chakra } from "@chakra-ui/react";

const LargeHeading = chakra("h1", {
  baseStyle: {
    fontSize: "44px",
    fontWeight: "bold",
  },
});

const Heading = chakra("h3", {
  baseStyle: {
    fontSize: "28px",
    fontWeight: 600,
  },
});

const SubHeading = chakra("p", {
  baseStyle: {
    fontSize: "22px",
    fontWeight: 500,
  },
});

const Body1 = chakra("p", {
  baseStyle: {
    fontSize: "18px",
  },
});

const Body2 = chakra("p", {
  baseStyle: {
    fontSize: "16px",
  },
});

const Caption1 = chakra("p", {
  baseStyle: {
    fontSize: "14px",
    color: "#878787",
  },
});

const Caption2 = chakra("p", {
  baseStyle: {
    fontSize: "12px",
    color: "#878787",
  },
});

const Caption3 = chakra("p", {
  baseStyle: {
    fontSize: "10px",
    color: "#878787",
  },
});

export {
  Caption1,
  Caption2,
  Body1,
  Body2,
  Heading,
  LargeHeading,
  SubHeading,
  Caption3,
};
