import { HStack, VStack } from "@chakra-ui/react";
import { FC, useState } from "react";
import { NotificationRequest } from "../../../core/models/notification/NotificationRequest";
import Image from "../Image";
//import Link from "../Link";
import Link from "../NextLink";
import { Caption3 } from "../typography";
import { format, formatDistance, subDays, subMinutes } from 'date-fns'
import { useRouter } from "next/router";
import { utils } from "ethers";

interface NotificationListProps {
  notifications?: NotificationRequest[] | null;
  selectedNotification?: NotificationRequest | null;
  myType?: number;
  onClose?: () => void;
}

const NotificationList: FC<NotificationListProps> = (props) => {
  const { notifications, myType, onClose } = props;
  const [selectedNtf, setSelectedNtf] = useState<NotificationRequest>();
  const router = useRouter();

  return (
    <VStack align="stretch" spacing={4}
      maxHeight={"265px"}
      overflowX="hidden"
      scrollBehavior={"smooth"}>
      {notifications?.map((notification, index) => (
        <Link key={index}
          onClick={() => onClose?.()}
          href={
            notification?.notificationRequestDetails?.ntfName?.toLowerCase() == "dispute" ?
              `/disputes` :
              `/find-employee/${Buffer.from(notification?.candidateProfile?.userId?.toString()).toString('base64')}`
          }
          href2={notification?.notificationRequestDetails?.ntfName?.toLowerCase() == "dispute" ?
            (myType == 1 ?
              {
                pathname: '/disputes',
                query: {
                  id:
                    Buffer.from(utils.keccak256(
                      utils.toUtf8Bytes(notification?.notificationRequestDetails?.ntfId)
                    )).toString('base64')
                },
              } as any :
              {
                pathname: '/profile/employee',
                query: {
                  id:
                    Buffer.from(utils.keccak256(
                      utils.toUtf8Bytes(notification?.notificationRequestDetails?.ntfId)
                    )).toString('base64')
                },
              })
            : myType == 1 ?
              {
                pathname: '/find-employee/[id]',
                query: { id: Buffer.from(notification?.candidateProfile?.userId?.toString()).toString('base64') },
              } : {
                pathname: '/requests/employee',
                query: {
                  id:
                    Buffer.from(utils.keccak256(
                      utils.toUtf8Bytes(notification?.notificationRequestDetails?.ntfId)
                    )).toString('base64')
                },
              }
          }
          w="-moz-fit-content">
          <HStack spacing={3}>
            <Image
              src={notification?.notificationRequestDetails?.idSenderEmp ?
                notification?.candidateProfile?.profilePic :
                notification?.orgProfile?.compPic}
              alt={notification?.notificationRequestDetails?.idSenderEmp ?
                notification?.candidateProfile?.firstName :
                notification?.orgProfile?.compName}
              boxSize="37px"
              rounded="full"
            />
            <VStack align="stretch" spacing={1} w="-webkit-fill-available">
              <HStack justifyContent="space-between" >
                <Caption3 color="black" fontWeight="600">
                  {notification?.notificationRequestDetails?.idSenderEmp ?
                    `${notification?.candidateProfile?.firstName} ${notification?.candidateProfile?.middleName ?? ""} ${notification?.candidateProfile?.lastName}` :
                    notification?.orgProfile?.compName}
                </Caption3>
                <Caption3 color="secondary" fontWeight="600" style={{marginRight:"5px"}}>
                  {notification?.notificationRequestDetails?.ntfName}
                </Caption3>
              </HStack>

              <Caption3>{notification?.notificationRequestDetails?.ntfDate && formatDistance(subMinutes(new Date(notification?.notificationRequestDetails?.ntfDate?.toString()), 0), new Date(), { addSuffix: true })}</Caption3>
            </VStack>
          </HStack>
        </Link>
      ))}
    </VStack>
  );
};

export async function getServerSideProps() {
  return {
    props: {},
  };
}

const mapState = (state: any) => {
  return {
    candidateProfile: state.PublicProfile,
    orgProfile: state.OrgNotificationProfile,
    notificationRequestDetails: state.NotificationRequestDetails,
    unique_link: state.unique_link,
    item_hash: state.item_hash,
    item_ref: state.item_ref,
  }
}

// NotificationList.propTypes = {
//   selectedNotification: null;
// };

export default NotificationList;
