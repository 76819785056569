import { httpClient, handleResponse, handleError } from "./HttpInterceptor";
import { AxiosRequestConfig } from "axios";

// API call to save json
export const saveJsonToStorage = async (data: any) =>
  await httpClient
    .post("/api/common/saveJsonToStorage", data)
    .then(handleResponse)
    .catch(handleError);

// API call to get json from storage
export const getJsonFromStorage = async (data: any) =>
  await httpClient
    .post("/api/common/getJsonFromStorage", data)
    .then(handleResponse)
    .catch(handleError);

// API call to upload file to storage
export const uploadFileToStorage = async (
  data: any,
  config: AxiosRequestConfig
) =>
  await httpClient
    .post("/api/common/uploadFileToStorage", data, config)
    .then(handleResponse)
    .catch(handleError);
