import { httpClient } from "./HttpInterceptor";

const orgService = {
  GetOrgByWalletAddress: async (token: any, data: any) =>
    await httpClient.post("/api/reg-org/getOrgByWalletAddress", data),

  CreateOrg: async (token: any, data: any) =>
    await httpClient.post("/api/reg-org/saveOrgDetails", data),

  CreateOrganizationProfileHistroy: async (token: any, data: any) =>
    await httpClient.post("/api/reg-org/saveOrganizationProfileHistroy", data),

  GetAllOrganizationDetails: async (token: any, data: any) =>
    await httpClient.post("/api/reg-org/getAllOrg", data),

  GetAllOrganizations: async (token: any, data: any) =>
    await httpClient.post("/api/reg-org/getAllOrgProfile", data),

  GetAllOrganizationsWithJob: async (token: any, data: any) =>
    await httpClient.post(
      "/api/reg-org/getAllOrganizationsWithJob",
      data
    ),
};

export default orgService;
