import { AxiosRequestConfig } from "axios";
import {
  saveJsonToStorage,
  uploadFileToStorage,
  getJsonFromStorage,
} from "../../components/services/Web3StorageService";

// SaveJson to web3storage using API
const saveJson = async (inputJson: any) => {
  return await saveJsonToStorage(inputJson);
};

// GetJson from web3storage using API
const getJson = async (cid: any) => {
  if (cid) {
    return await getJsonFromStorage(cid);
  }
};

// upload image using API
// returns web3storage cid
const uploadImage = async (file: any) => {
  if (!file) return;
  return await uploadFile(file);
};

// uploadFile using API
// returns web3storage cid
const uploadFile = async (file: File, config?: AxiosRequestConfig) => {
  if (!file) return;

  // Need to post the files as part of FormData
  // along with content-type as multipart/formdata
  const formData = new FormData();
  formData.append("file", file);
  const uploadConfig = {
    headers: { "content-type": "multipart/formdata" },
    ...config, // merge additional config if provided
  };
  return await uploadFileToStorage(formData, uploadConfig);
};

export { saveJson, getJson, uploadImage, uploadFile };
