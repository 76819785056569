import { Circle } from "@chakra-ui/react";
import _ from "lodash";
import { FC, useState } from "react";
import { IoMdCheckmark, IoMdClose, IoMdWallet } from "react-icons/io";
import { useModalContext } from "hooks/useContext";
import { useRouter } from "next/router";
import useUser from "hooks/useUser";
import WalletLoginDrawer from "../drawer/WalletLoginDrawer";

interface WalletMenuProps { }

const WalletMenu: FC<WalletMenuProps> = (props) => {
  const router = useRouter();
  const { walletLoginDrawer } = useModalContext();
  const { onOpen } = walletLoginDrawer;

  const handleOnClick = () => {
    onOpen();
  }

  const { user: currentUser } = useUser({
    redirectTo: '/',
    redirectIfFound: true,
  })

  return (
    <>
      <WalletLoginDrawer />
      <Circle bg="white" size={30} cursor={"pointer"} onClick={handleOnClick}>
        <IoMdWallet size={35 * 0.6} color="#264252" style={{ marginTop: "-3px", marginLeft: "-4px" }} />
        <Circle bg="#264252" size={3} cursor={"pointer"} position={"absolute"} marginRight={"-8px"} marginBottom={"-9px"}>
          {!currentUser?.address ? (
            <IoMdClose color="white" size={16 * 0.6} />
          ) : (
            <IoMdCheckmark color="white" size={16 * 0.6} />
          )}
        </Circle>
      </Circle>
    </>
  );
};

export default WalletMenu;
