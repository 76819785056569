import { Box, Flex, List, ListItem, Link, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { AiFillLinkedin } from "react-icons/ai";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";

import { public_routes } from "../../data/routes";
import Logo from "./Logo";
import useUserDetails from "hooks/useUserDetails";

type LinkItemProps = {
  text?: string;
  isTag?: boolean;
  tagText?: string;
  path?: string;
};

const LinkItem = ({ text, isTag = false, tagText, path }: LinkItemProps) => {
  const router = useRouter();

  return (
    <ListItem display="flex">
      <Link
        fontWeight="600"
        href="#"
        color="rgba(113, 128, 150, 1)"
        onClick={(e) => {
          if (path) {
            e.preventDefault();
            router.push(path);
          }
        }}
      >
        {text}
      </Link>
      {isTag && (
        <Text
          as="span"
          bg="#008F94"
          px="0.25rem"
          display="inline-flex"
          alignItems="center"
          color="#fff"
          height="1.25rem"
          borderRadius="0.25rem"
          ml="0.25rem"
          mt="0.25rem"
          fontSize="0.75rem"
        >
          {tagText}
        </Text>
      )}
    </ListItem>
  );
};
const Footer = () => {
  const routes = public_routes;
  const router = useRouter();
  const { user: currentUser } = useUserDetails();
  const nestedRoles = [2];

  return (
    <Box
      as="footer"
      bg="gray.100"
      borderTop="1px solid"
      borderColor="gray.300"
      py="2.5rem"
      fontSize="0.875rem"
    >
      <Box
        w="full"
        marginX="auto"
        pb="1rem"
        mb="1.5rem"
        px={{ base: "1rem", lg: "0" }}
        borderBottom="1px solid"
        display={"flex"}
        justifyContent="center"
        borderColor="gray.300"
      >
        <Flex
          justify="center"
          flexWrap="wrap"
          alignItems="center"
          width={"full"}
        >
          <Box w="inherit" mb={{ base: "1.5rem", lg: "0" }}>
            <Box w="max" mx="auto" mb="2">
              <Logo src="/images/nestedblack.png" />
            </Box>
            <List
              lineHeight="2"
              justifyContent={"center"}
              display="grid"
              mb="1.5rem"
            >
              <Link
                fontWeight="500"
                href="#"
                color="black"
                display={"none"}
                justifyContent="center"
              >
                Platform Guide
              </Link>
              <Link
                fontWeight="500"
                href="#"
                color="black"
                display={"flex"}
                justifyContent="center"
                onClick={(e) => {
                  e.preventDefault();
                  router.push("/contact-us");
                }}
              >
                Contact
              </Link>
            </List>
            <Flex
              justify="start"
              mb="0.5rem"
              alignItems="center"
              justifyContent={"center"}
              marginInlineStart="2rem"
            >
              <Link
                href="https://twitter.com/NestedClub"
                mr="2rem"
                target="_blank"
              >
                <BsTwitter
                  style={{ marginBottom: "-2px" }}
                  size={20}
                  fill="#008F94"
                  aria-label="Navigate to Twitter"
                  focusable="false"
                />
              </Link>
              <Link
                href="https://www.linkedin.com/company/90594042/"
                mr="2rem"
                target="_blank"
              >
                <AiFillLinkedin
                  style={{
                    stroke: "#008F94",
                    strokeWidth: "5",
                    marginBottom: "-2px",
                  }}
                  size={20}
                  fill="#008F94"
                  aria-label="Navigate to Linkedin"
                  focusable="false"
                />
              </Link>
              <Link href="#" mr="2rem" display={"none"}>
                <FaTelegramPlane
                  size={20}
                  fill="#008F94"
                  aria-label="Navigate to Telegram"
                  focusable="false"
                />
              </Link>
              <Link
                href="https://www.instagram.com/nestedclub/"
                mr="2rem"
                target="_blank"
              >
                <svg
                  style={{ width: "1rem", height: "1rem" }}
                  fill="#008F94"
                  viewBox="0 0 24 24"
                  role="img"
                  aria-label="Navigate to Instagram"
                  focusable="false"
                >
                  <path
                    d="m23.09.91c-.61-.61-1.33-.91-2.17-.91h-17.84c-.85 0-1.57.3-2.17.91s-.91 1.33-.91 2.17v17.84c0 .85.3 1.57.91 2.17s1.33.91 2.17.91h17.84c.85 0 1.57-.3 2.17-.91s.91-1.33.91-2.17v-17.84c0-.85-.3-1.57-.91-2.17zm-14.48 7.74c.94-.91 2.08-1.37 3.4-1.37 1.33 0 2.47.46 3.41 1.37s1.41 2.01 1.41 3.3-.47 2.39-1.41 3.3-2.08 1.37-3.41 1.37c-1.32 0-2.46-.46-3.4-1.37s-1.41-2.01-1.41-3.3.47-2.39 1.41-3.3zm12.66 11.63c0 .27-.09.5-.28.68a.92.92 0 0 1 -.67.28h-16.7a.93.93 0 0 1 -.68-.28.92.92 0 0 1 -.27-.68v-10.13h2.2a6.74 6.74 0 0 0 -.31 2.05c0 2 .73 3.71 2.19 5.12s3.21 2.12 5.27 2.12a7.5 7.5 0 0 0 3.75-.97 7.29 7.29 0 0 0 2.72-2.63 6.93 6.93 0 0 0 1-3.63c0-.71-.11-1.39-.31-2.05h2.11v10.12zm0-13.95c0 .3-.11.56-.31.77a1.05 1.05 0 0 1 -.77.31h-2.72c-.3 0-.56-.11-.77-.31a1.05 1.05 0 0 1 -.31-.77v-2.58c0-.29.11-.54.31-.76s.47-.32.77-.32h2.72c.3 0 .56.11.77.32s.31.47.31.76z"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </Link>
              <Link
                href="https://www.facebook.com/people/Nested-Club/100090953042366/"
                mr="2rem"
                target="_blank"
              >
                <BsFacebook
                  size={20}
                  fill="#008F94"
                  aria-label="Navigate to Facebook"
                  focusable="false"
                />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCIOQ3pgKCThNbTHWiljYJlw"
                mr="2rem"
                target="_blank"
              >
                <BsYoutube
                  size={20}
                  fill="#008F94"
                  aria-label="Navigate to Youtube"
                  focusable="false"
                />
              </Link>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Flex
        wrap="wrap"
        mx="auto"
        alignItems="center"
        w="inherit"
        justifyContent="center"
      >
        <Link
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/register/candidate"}
        >
          Join Us
        </Link>
        <Link color="gray.600" fontWeight={500} p={2} href={"/login"}>
          Log In
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/faq"}
        >
          Help
        </Link>
        {
          (currentUser === null || (currentUser?.roleId && (nestedRoles.includes(currentUser.roleId) || currentUser.roleId <= 0))) && (
            <>
              <Link color="gray.600" fontWeight={500} p={2} href={"/delete-profile"}>
                Want to delete my profile
              </Link>
              <Link color="gray.600" fontWeight={500} p={2} href={"/jobs/search-job"}>
                Job Seeker
              </Link>
            </>
          )
        }
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/privacy/policy"}
        >
          Privacy Policy
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/legal/acceptable-use-policy"}
        >
          Acceptable Use Policy
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/privacy/policy/cookies"}
        >
          Cookie Policy
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/privacy/policy/copyright"}
        >
          Copyright Policy
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/legal/disclaimer"}
        >
          Disclaimers
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/legal/terms"}
        >
          Terms of Use
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/legal/user-agreement"}
        >
          User Agreement
        </Link>
        <Link
          target="_blank"
          color="gray.600"
          fontWeight={500}
          p={2}
          href={"/legal/end-user-license-agreement"}
        >
          End User License Agreement
        </Link>
      </Flex>
      <Flex mx="auto" alignItems="center" w="inherit" justifyContent={"center"}>
        <Text color="gray.600" fontSize="0.875rem">
          All rights reserved by &copy; Copyright {new Date().getFullYear()}.
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
