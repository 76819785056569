import { Spinner, useDisclosure } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import Stack from "../Stack";
import Image from "../../shared/Image";
const LoaderGif = '/images/loader.gif'

interface PageLoaderProps {
    show?: boolean;
}

const PageLoader: FC<PageLoaderProps> = (props) => {
    const { show = false } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {
                show && (
                    <Stack className="loader-container"
                        position={"fixed"}
                        top={0}
                        left={0}
                        w="full"
                        h="-webkit-fill-available"
                        bg="#F8F8F8AD"
                        zIndex={2345678}
                    >
                        <Stack className="loader"
                               position="absolute"
                               top="50%"
                               left="50%"
                               transform="translate(-50%, -50%)">
                            {/* <Image src={LoaderGif} alt="Loader..." /> */}
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </Stack>
                    </Stack>
                )
            }

        </>
    );
};
export default PageLoader;