/**
 * User API Service
 * 
 * This module provides a set of functions for interacting with the user-related
 * endpoints on the server. It facilitates operations related to user profiles,
 * education details, work experiences, social profiles, skills, and organizational
 * profiles. The functions use the httpClient for making HTTP requests with the 
 * appropriate endpoints and data.
 * 
 * Functions:
 */
import { httpClient } from "./HttpInterceptor";

const usersService = {
  /**
   * Retrieve user profile by user unique ids.
   *
   * This function makes an API call to retrieve a user profile based on the provided
   * unique ids. The unique ids can be identified using various parameters such as
   * panNumber (ReferralId), emailId, or userId.
   * 
   * @param token - Authorization token for authentication.
   * @param data - Request data containing unique ids (panNumber, emailId, or userId).
   * @returns A promise that resolves with the result of the API call, which is of type
   * `Users` (DB Type) representing the user details or `null` if no user is found.
   */
  GetUserByWalletAddress: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/getCandidateByWalletAddress", data),

  /**
   * Retrieve candidate profile by user unique ids.
   *
   * This function makes an API call to retrieve a user profile based on the provided
   * unique ids. The unique ids can be identified using various parameters such as
   * panNumber (ReferralId), emailId, or wallet address (from session).
   * 
   * @param token - Authorization token for authentication.
   * @param data - Request data containing unique ids (panNumber, emailId, or wallet address).
   * @returns A promise with the result of the API call, which is of type
   * `CandidateProfile` representing the user details or `empty object` if no user is found.
   */
  GetCandidateProfileByWalletAddress: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/getCandidateProfileByWalletAddress", data),

  /**
   * Retrieve filtered candidate profile by user unique ids -wallet address (from session),
   *  panNumber (ReferralId), userId, emailId, showPrivate, showSocial, showExperience, showEducation.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing user unique ids (wallet address, panNumber, userId, emailId,
   *  showPrivate, showSocial, showExperience, showEducation) and filtering criteria.
   * @returns A promise with the result of the API call, which is of type
   * `CandidateProfile` representing the user details with credit history or `empty object` if no user is found.
   */
  GetUserFilteredProfileByWalletAddress: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/getFilteredCandidateProfileByWalletAddress", data),

  /**
   * Retrieve candidate profile by user Id or email Id .
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing user ID or email id.
   * @returns A promise with the result of the API call, which is of type
   * `CandidateProfile` representing the user details with credit history or `empty object` if no user is found.
   */
  GetCandidateProfileByUserId: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/getCandidateProfileByUserId", data),

  /**
   * Retrieve candidate profiles by role IDs- numberic array.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing role IDs.
   * @returns A promise with the result of the API call, which is of type
   * `CandidateProfile[]` representing the array of user details with
   *  public and private profile only or `empty object` if no user is found.
   */
  GetCandidateProfilesByRoleId: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/getCandidateProfilesByRoleId", data),

  /**
   * Create or update user profile with public details.
   * Update parameters to find the user - Id(UserId) | Email | UniqueHash | Referral_Id
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing public profile details.
   * @returns A promise with the result of the API call, which is of type
   * `Users` (DB Type) representing the user details.
   */
  CreateUser: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/savePublicDetails", data),

  /**
   * Save public profile history details for a user.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing public profile history details.
   * @returns A promise with the result of the API call, which is of type
   * `UserPublicProfileHistory` (DB Type) representing the user public detail history.
   */
  CreateUserPublicProfileHistroy: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/savePublicHistroyDetails", data),

  /**
   * Create or Update user private details reference.
   * Update parameters to find the user - UserId
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing private profile details. 
   * @returns A promise with the result of the API call, which is of type
   * `UserPrivateProfile` (DB Type) representing the user private details.
   */
  CreateUserPrivateProfile: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/savePrivateDetails", data),

  /**
   * Save private profile history details for a user.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing private profile history details.
   * @returns A promise with the result of the API call, which is of type
   * `UserPublicProfileHistory` (DB Type) representing the user private details.
   */
  CreateUserPrivateProfileHistroy: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/savePrivateHistroyDetails", data),

  /**
   * Delete user account by user ID or email Id.
   *
   * @param token - Authorization token for authentication.
   * @param data - Delete all data containing user ID from all the tables compeletly.
   * @returns A promise with the result of the API call.
   */
  DeleteUserById: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/deleteUserAccount", data),

  /**
   * Create or Update candidate education entry.
   * Update parameters to find the user education - UserId and Id(Education Identity Id)
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing candidate education details.
   * @returns A promise with the result of the API call, which is of type
   * `UserEducation` (DB Type) representing the user education details.
   */
  CreateCandidateEducation: async (token: any, data: any) =>
    await httpClient.post("/api/user-education/saveCandidateEducationDetails", data),

  /**
   * Save education history details for a candidate.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing education history details. 
   * @returns A promise with the result of the API call, which is of type
   * `UserEducationHistory` (DB Type) representing the user education history details.
   */
  CreateCandidateEducationHistroy: async (token: any, data: any) =>
    await httpClient.post("/api/user-education/saveCandidateEducationHistroyDetails", data),

  /**
   * Delete candidate education entry by EducationId and UserId.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing education entry ID.
   * @returns A promise with the result of the API call.
   */
  DeleteCandidateEducation: async (token: any, data: any) =>
    await httpClient.post("/api/user-education/deleteCandidateEducationDetails", data),

  /**
   * Retrieve candidate educations by user unique ids- panNumber(Referral Id) | userId | emailId, and showEducation .
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing user ID.
   * @returns A promise with the result of the API call, which is of type- if showEducation is
   * true then `CandidateProfile` representing the array of user details with
   *  public and education details (list) only else TotalEducation (deleted educations also).
   */
  GetCandidateEducationsByUserId: async (token: any, data: any) =>
    await httpClient.post("/api/user-education/getCandidateEducationsByUserId", data),

  /**
   * Create or Update candidate work experience entry.
   * Update parameters to find the user education - UserId and CompanyId and ExperienceUniqueHash
   *  Or UserId and CompanyId and Id(ExperienceId)
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing candidate work experience details. 
   * @returns A promise with the result of the API call, which is of type
   * `UserExperience` (DB Type) representing the user experience details.
   */
  CreateCandidateExperience: async (token: any, data: any) =>
    await httpClient.post("/api/user-experience/saveCandidateExperienceDetails", data),

  /**
   * Save work experience history details for a candidate.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing work experience history details.
   * @returns A promise with the result of the API call, which is of type
   * `UserExperienceHistory` (DB Type) representing the user experience history details.
   */
  CreateCandidateExperienceHistroy: async (token: any, data: any) =>
    await httpClient.post("/api/user-experience/saveCandidateExperienceHistroyDetails", data),

  /**
   * Delete candidate work experience entry by UserId and CompanyId and ExperienceId.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing work experience entry ID.
   * @returns A promise with the result of the API call.
   */
  DeleteCandidateExperience: async (token: any, data: any) =>
    await httpClient.post("/api/user-experience/deleteCandidateExperienceDetails", data),

  /**
   * Retrieve candidate work experiences by user unique Ids- wallet Address(from session),
   * panNumber (ReferralId), userId, emailId, showPrivate, showExperience.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing user ID.
   * @returns A promise with the result of the API call, which is of type of- showExperience is true
   * then `CandidateProfile` representing the public profile, experience or private profile (if true)
   * else it will return TotalExperience (total experience added even if deleted)
   * 
   */
  GetCandidateExperiencesByUserId: async (token: any, data: any) =>
    await httpClient.post("/api/user-experience/getCandidateExperiencesByUserId", data),

  /**
   * Create or Update candidate social profile entry.
   * Update parameters to find the user social profile - UserId
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing candidate social profile details. 
   * @returns A promise with the result of the API call, which is of type
   * `UserSocialProfile` (DB Type) representing the user social profile.
   */
  CreateCandidateSocialProfile: async (token: any, data: any) =>
    await httpClient.post("/api/user-social-profile/saveCandidateSocialProfileDetails", data),

  /**
   * Save social profile history details for a candidate.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing social profile history details.
   * @returns A promise with the result of the API call, which is of type
   * `UserSocialProfileHistory` (DB Type) representing the user social profile history.
   */
  CreateCandidateSocialProfileHistroy: async (token: any, data: any) =>
    await httpClient.post("/api/user-social-profile/saveCandidateSocialProfileHistroyDetails", data),

  /**
   * Create or Update candidate skills details.
   * Update parameters to find the user skills - UserId
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing candidate skills details. 
   * @returns A promise with the result of the API call, which is of type
   * `UserSkills` (DB Type) representing the user skills.
   */
  CreateCandidateSkills: async (token: any, data: any) =>
    await httpClient.post("/api/user-skills/saveCandidateSkillsDetails", data),

  /**
   * Retrieve organizational profiles by user ID.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing user ID.
   * @returns A promise with the result of the API call, which is of type
   * `OrgProfile[]`.
   */
  GetOrgProfilesByUserId: async (token: any, data: any) =>
    await httpClient.post("/api/user-profile/getOrganizationsByUserId", data),

  /**
   * Retrieve all candidate work experiences by CompanyId and IsCurrentOrg
   * which are not deleted. Basically it will show total working employees working
   * in organization or those have worked in organization based on IsCurrentOrg parameter.
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing company ID.
   * @returns A promise with the result of the API call, which is of type
   * `UserExperience[] (DB Type)`.
   */
  GetCandidateExperiencesByCompanyId: async (token: any, data: any) =>
    await httpClient.post("/api/user-experience/getCandidateExperiencesByCompanyId", data),

  /**
   * Create or Update candidate additional details.
   * Update parameters to find the user additional details - UserId
   *
   * @param token - Authorization token for authentication.
   * @param data - Request data containing candidate additional details.
   * @returns A promise with the result of the API call, which is of type
   * `UserAdditionalInfo (DB Type)`.
   */
  SaveCandidateAdditionalDetails: async (token: any, data: any) =>
    await httpClient.post("/api/user-additional-info/saveUserAdditionalDetails", data),

};
export default usersService;
