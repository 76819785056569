import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/modal";
import { Box, Divider, HStack, Stack as NativeStack, List, ListItem, SimpleGrid, Tooltip, useToast } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useModalContext } from "../../../hooks/useContext";
import { Body1, Caption2, Heading } from "../typography";
import _ from "lodash";
import PageLoader from "../page-loader/PageLoader";
import Image from "@/components/shared/Image";
import Stack from "../Stack";
import LocalStorageService from "../../../core/service/LocalStorageService";
import Container from "../Container";
import { ButtonWallet } from "../button/ButtonPrimary";
import Card from "../Card";
import { useRouter } from "next/router";
import { useAccount, useConnect, useNetwork, useSignMessage } from "wagmi";
import useUser from "hooks/useUser";
import { OrgProfile } from "@/core/models/org";
import useUserDetails from "hooks/useUserDetails";
import { SiweMessage } from "siwe";
import orgService from "@/components/services/OrgService";
import AuthUser from "types/AuthUser";
import { saveJson } from "@/core/storage/web3-store";
import { convertToOrganization } from "@/core/utility/organization";

interface WalletLoginDrawerProps {

}

const WalletLoginDrawer: FC<WalletLoginDrawerProps> = (props) => {
  const { walletLoginDrawer } = useModalContext();
  const { isOpen, onClose, onOpen } = walletLoginDrawer;

  const { walletConnectModal, confirmDialogModal } = useModalContext();
  const router = useRouter();
  const toast = useToast();
  const [showSpinner, setShowSpinner] = useState(false);
  const { connectors, connect } = useConnect();
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork()
  const { signMessageAsync } = useSignMessage()
  const { user: currentUser, mutateUser } = useUser({
    redirectTo: '/',
    redirectIfFound: true,
  })

  const [organizationProfile, setOrganizationProfile] = useState<OrgProfile>();
  const { user: activeUser } = useUserDetails();

  const [state, setState] = useState<{
    loading?: boolean
    nonce?: string
    isLoggedIn?: boolean
  }>({})

  useEffect(() => {
    const loadOrgData = async () => {
      if (activeUser && activeUser?.userId && activeUser?.userId > 0) {
        setShowSpinner(true);
        const model = {
          emailId: activeUser?.emailId,
          companyId: '',
        }

        let resp = await orgService.GetOrgByWalletAddress('token', model);
        if (resp && resp?.status == 200) {
          const organization = resp?.data as OrgProfile;
          if (!_.isEqual(organizationProfile, organization)) {
            setOrganizationProfile(organization);
          }

          setShowSpinner(false);
        }
      }
    }
    loadOrgData();
  }, [activeUser, organizationProfile]);

  const fetchNonce = async () => {
    try {
      const nonceRes = await fetch('/api/nonce')
      const nonce = await nonceRes.text()
      setState((x) => ({ ...x, nonce: nonce }))
    } catch (error) {
      setState((x) => ({ ...x, error: error as Error }))
      console.log(error)
    }
  }

  const signIn = async () => {
    try {
      const chainId = chain?.id
      if (!address || !chainId || state.isLoggedIn === true) return

      setState((x) => ({ ...x, loading: true }))
      // Create SIWE message with pre-fetched nonce and sign with wallet
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign message for secure login.',
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce: state.nonce,
      })
      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      })

      // Verify signature
      const verifyRes = await fetch('/api/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, signature }),
      });

      if (!verifyRes?.ok) throw new Error('Error verifying message')

      setState((x) => ({ ...x, loading: false, isLoggedIn: true }))

      const userData: any = verifyRes.json();

      if (userData) {
        mutateUser(userData, false);
      }

    } catch (error) {
      setState((x) => ({ ...x, loading: false, nonce: undefined }))
      //onError({ error: error as Error })
      fetchNonce()
    }
  }

  const handleOrgWalletCheck = async () => {
    if (organizationProfile) {

      if (_.isEmpty(organizationProfile?.WalletAddress)) {
        confirmDialogModal.onOpen();
        return;
      } else if (organizationProfile && organizationProfile?.WalletAddress == address) {
        await signIn();
        onClose();
      } else if (organizationProfile && organizationProfile?.WalletAddress !== address) {
        toast({
          title: "You are trying to connect with different wallet. Please verify if you are connecting with correct wallet. Wrong wallet connected!",
          status: "error",
          duration: 3000,
          position: 'bottom-right',
          variant: 'left-accent',
          isClosable: true
        });
      } else {
        console.log("error while sign in the wallet")
        toast({
          title: "Something went wrong..... Error while sign in the wallet!",
          status: "error",
          duration: 3000,
          position: 'bottom-right',
          variant: 'left-accent',
          isClosable: true
        });
      }

    }
  }


  const routeUser = async () => {
    if (address && currentUser?.address && address === currentUser?.address) {
      let checkExist = currentUser as AuthUser;
      switch (checkExist.roleId) {
        case 3:
        case 4:
        case 5:
          LocalStorageService.clearUserWalletRoleDetails();
          LocalStorageService.setUserWalletRoleDetails(checkExist);
          LocalStorageService.clearPlatformUserDetails();
          router.push(checkExist.roleId == 3 ? '/platform-user/support' :
            checkExist.roleId == 4 ? '/platform-user/candidate' :
              '/platform-user/manager/organization');
          break;
        case 2:
          if (checkExist.isRegistered) {
            router.push("/dashboard/employee");
          } else {
            router.push("/register/employee");
          }
          break;
        case 1:
          if (checkExist.isRegistered) {
            router.push("/profile/company");
          }
          break;
        default:
          if (!checkExist && router.asPath.includes("register/company")) {
            //allow company to register from link directly
            router.push(router.asPath);
          }
          else if (!address && router.asPath.includes("login")) {
            //allow platform users to login from link directly
            //If no wallet is connected remain on login page
            router.push(router.asPath);
          } else if (!checkExist || (checkExist && checkExist.roleId == 0)) {
            // if wallet connected and wallet address doesn't exit- goto create profile
            router.push("/");
          }
      }
    }
  }

  const ethLogin = async () => {
    if (isConnected && !state.loading) {
      setState((x) => ({ ...x, loading: true }));
      //await signIn();
      //await routeUser();
      await handleOrgWalletCheck();
      setState((x) => ({ ...x, loading: false }));
    }
  };

  useEffect(() => { if (!currentUser?.isWalletConnected) fetchNonce(); }, [currentUser]);

  useEffect(() => { ethLogin(); }, [isConnected, organizationProfile]);

  const handleSocialLogin = () => {
    handleLogin(connectors[0]);
  };

  const handleWeb3Login = () => {
    handleLogin(connectors[1]);
  };

  const handleLogin = async (connector: any) => {
    if (!isConnected) {
      await connect({ connector: connector });
    }
    await ethLogin();
  };

  const handleSaveExperienceWallet = async () => {

    if (!organizationProfile || !address) {
      toast({
        title: "Error while saving wallet to organization!",
        status: "error",
        duration: 3000,
        position: 'bottom-right',
        variant: 'left-accent',
        isClosable: true
      });
      return;
    }
    const dataJsonString = JSON.stringify(organizationProfile);
    const orgIpfsRef = await saveJson(dataJsonString);
    const org = await convertToOrganization(organizationProfile, address, orgIpfsRef, '');
    let respdata = await orgService.CreateOrg('token', org);
    if (respdata && respdata?.status == 200) {

      toast({
        title: "Wallet connected successfully with organization!",
        status: "success",
        duration: 3000,
        position: 'bottom-right',
        variant: 'left-accent',
        isClosable: true
      });
      await signIn();
    } else {
      toast({
        title: "Error while saving wallet to organization!",
        status: "error",
        duration: 3000,
        position: 'bottom-right',
        variant: 'left-accent',
        isClosable: true
      });
    }
  };

  return (
    <>
      <PageLoader show={showSpinner} />
      <Drawer size="lg" isOpen={isOpen} placement="right"
        onClose={() => {
          onClose();
        }}>
        <DrawerOverlay />
        <DrawerContent p="0">
          <DrawerCloseButton />
          <DrawerHeader>
            <Stack >
              <Caption2 fontWeight="500" color={"black"} fontSize="20px">
                Wallet Login
              </Caption2>
            </Stack>
          </DrawerHeader>
          <Divider my="1" />
          <HStack></HStack>
          <DrawerBody p="0">
          <Box>
        <Container minH="190">
          <Card
            py={{
              base: 2,
              lg: 0,
            }}
            px="6"
            my={{ base: 0, lg: 8 }}
            minH="max-content"
          >
            <Stack justify="space-between" alignItems="center" spacing={4}>
              <Image
                mt="4"
                src="/images/login-header.svg"
                alignSelf={{
                  base: "center",
                  lg: "start",
                }}
                alt=""
                w="202px"
                h="111px"
              />
              <Body1
                fontSize="22px"
                textAlign={{
                  base: "center",
                  lg: "left",
                }}
              >
                <strong>Welcome to Nested Wallet, </strong><br />
                Connect the wallet with your organization to perform various task in nested.
              </Body1>
              <Stack w="58px" h="45px" display="flex" justifyContent="center" alignItems="center" style={{ marginRight: "20px" }}>
              </Stack>
            </Stack>
          </Card>
        </Container>
        <Container p="2">
          <Box position={'relative'}>
            <Container
              as={SimpleGrid}
              maxW={'7xl'}
              columns={{ base: 1, md: 2 }}
              spacing={{ base: 5, lg: 10 }}
              py={{ base: 2, sm: 2, lg: 0 }}>
              <NativeStack
                bg={'gray.50'}
                rounded={'xl'}
                p={{ base: 4, sm: 6, md: 8 }}
                spacing={{ base: 8 }}
                maxW={{ lg: 'lg' }}
                boxShadow="dark-lg"
              >
                <NativeStack spacing={4}>
                  <Image src="/images/social-image.svg" alt='Social Login' w={10} />
                  <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: '2xl' }}>
                    Social Login
                  </Heading>
                  <List spacing={3}>
                    <ListItem>
                      Use your existing Google, Microsoft, Github, or LinkedIn account to sign up for Web3 platforms.
                    </ListItem>
                    <ListItem>
                      No need to create a new account or learn new login credentials to get started on Web3.
                    </ListItem>
                  </List>
                </NativeStack>
                <Box as={'form'} mt={10}>
                  <NativeStack spacing={4}>
                    <ButtonWallet onClick={handleSocialLogin}
                      size='lg'
                      variant='solid'
                      disabled={!connectors[0].ready}
                      leftIcon={<Image src="/icons/torus_Icon.svg" alt="Torus" w={8} />}
                    >
                      Torus
                    </ButtonWallet>
                  </NativeStack>
                </Box>
              </NativeStack>
              <NativeStack
                bg={'gray.50'}
                rounded={'xl'}
                p={{ base: 4, sm: 6, md: 8 }}
                spacing={{ base: 8 }}
                maxW={{ lg: 'lg' }}
                boxShadow="dark-lg">
                <NativeStack spacing={4}>
                  <Image src="/images/wallet-image.svg" alt='Web3 Wallet' w={10} />
                  <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: '2xl' }}>
                    Wallet Login
                  </Heading>
                  <List spacing={3}>
                    <ListItem>
                      Web3 wallets are essential for secure and decentralized management of your digital assets.
                    </ListItem>
                    <ListItem>
                      Use popular Web3 wallets like MetaMask or Wallet Connect to securely store and manage your assets.
                    </ListItem>
                  </List>
                </NativeStack>
                <Box as={'form'} mt={10}>
                  <NativeStack spacing={4}>
                    <ButtonWallet onClick={handleWeb3Login}
                      size='lg'
                      variant='solid'
                      disabled={!connectors[1].ready}
                      leftIcon={<Image src="/icons/metamask_Icon.svg" alt="MetaMask" w={8} />}
                    >
                      MetaMask
                    </ButtonWallet>
                  </NativeStack>
                </Box>
              </NativeStack>
            </Container>
          </Box>

        </Container>
      </Box>
            
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default WalletLoginDrawer;
