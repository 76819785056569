import { chakra, Container as ChakraContainer } from "@chakra-ui/react";

const Container = chakra(ChakraContainer, {
  baseStyle: {
    maxWidth: "container.lg",
    p: 0,
  },
});

export default Container;
