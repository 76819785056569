import { Divider, Flex } from '@chakra-ui/react';
import Card from "../shared/Card";
import { Body1 } from "../shared/typography";
import { account_routes } from '../../data/routes';
import { useRouter } from 'next/router';
import NavItem from '../shared/NavItem';
import {
  BsBarChart,
  BsCreditCard,
  BsCurrencyExchange
} from 'react-icons/bs'
import { FaHandHoldingUsd } from 'react-icons/fa'
import React from 'react';

interface LeftPanelProps { }

const LeftPanel: React.FC<LeftPanelProps> = (props) => {
  const router = useRouter();

  const getNavIcon = (menuTitle: string) => {
      switch(menuTitle.toLowerCase()) {
        case 'buy': return BsCreditCard;
        case 'swap': return BsCurrencyExchange;
        case 'history': return BsBarChart;
        case 'vesting': return FaHandHoldingUsd;
      }
  }


    return (
    <Card
      w={{
        base: "100%",
        lg: "276px",
      }}
      spacing={[0, 0]}
    >
      <Flex
        p="2%"
        flexDir="column"
        w="100%"
        alignItems="flex-start"
        as="nav"
      >
        <Body1  px={6} pt={6} pb={3} fontFamily={'Montserrat'} fontWeight="600">{'Account'}</Body1>

        <Divider />
        {account_routes.map((menu, index) => (
          <>
            <NavItem key={index}
              icon={getNavIcon(menu.label)}
              active={menu.path === router?.pathname}
              path={menu.path}
              title={menu.label} />
          </>
        ))}
      </Flex>
    </Card>
  );
};
export default LeftPanel;
