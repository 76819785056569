import { utils } from "ethers";
import moment from "moment";
import * as CryptoJS from 'crypto-js'

/**
 * Generates an array of string representations of years, spanning from the current year
 * to the specified earliest year.
 *
 * @param earliestYear - The earliest year in the generated array.
 * @returns An array of strings representing years in descending order.
 */
export const getYears = (earliestYear: number = 1950): string[] => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - earliestYear + 1 }, (_, index) =>
        (currentYear - index).toString()
    );

    return years;
};

/**
 * Removes duplicate elements from an array of strings, preserving the order
 * of the first occurrence of each unique element.
 *
 * @param arr - The input array with potential duplicate elements.
 * @returns An array containing only the unique elements in the original order.
 */
export function removeDuplicates(arr: string[]): string[] {
    return Array.from(new Set(arr));
}

export const encryptAes = async (input: string, userKey: string) => {
    return CryptoJS.AES.encrypt(input, `${"#87TYBRt78*rtDGSaJ*$"}${userKey}`).toString();
}

export const decryptAes = async (chipertext: string, userKey: string) => {
    try {
        const responseBytes = CryptoJS.AES.decrypt(chipertext, `${"#87TYBRt78*rtDGSaJ*$"}${userKey}`);
        return responseBytes.toString(CryptoJS.enc.Utf8) as string;
    } catch (error) {
        return;
    }
}

export const encryptToHex = async (input: string, userKey?: string) => {
    try {

        const encodedString = Buffer.from(input).toString('base64');
        let encHexKey = Buffer.from(encodedString, 'utf8').toString('hex');
        const hexString = utils.hexlify(utils.toUtf8Bytes(encHexKey));
        return hexString;
    }
    catch {
        return;
    }
}

export const decryptFromHex = (chipertext: string, userKey?: string) => {
    try {
        let hexString = utils.toUtf8String(chipertext);
        let decHexKey = Buffer.from(hexString, 'hex').toString('utf8');
        let decKey = Buffer.from(decHexKey, 'base64').toString('ascii');
        return decKey;
    } catch {
        return;
    }
}

export const toUtcDate = (currentDate: Date | string) => {
    if (typeof currentDate === 'string' || currentDate instanceof String) {
        const parsedDate = new Date(currentDate);
        return parsedDate ?
            new Date(Date.UTC(
                parsedDate.getUTCFullYear(),
                parsedDate.getUTCMonth(),
                parsedDate.getUTCDate(),
                parsedDate.getUTCHours(),
                parsedDate.getUTCMinutes(),
                parsedDate.getUTCSeconds())) :
            parsedDate;
    }
    else {

        return currentDate ?
            new Date(Date.UTC(
                currentDate.getUTCFullYear(),
                currentDate.getUTCMonth(),
                currentDate.getUTCDate(),
                currentDate.getUTCHours(),
                currentDate.getUTCMinutes(),
                currentDate.getUTCSeconds())) :
            currentDate;
    }

}

export const toUTCDate = (currentDate: Date | string) => {
    if (typeof currentDate === 'string' || currentDate instanceof String) {
        const parsedDate = new Date(currentDate);
        let utcDate = parsedDate ?
            new Date(
                parsedDate.getUTCFullYear(),
                parsedDate.getUTCMonth(),
                parsedDate.getUTCDate(),
                parsedDate.getUTCHours(),
                parsedDate.getUTCMinutes(),
                parsedDate.getUTCSeconds()) :
            parsedDate;
        return moment.utc(utcDate.toString()).toDate();
    }
    else {

        let utcDate = currentDate ?
            new Date(currentDate.getUTCFullYear(),
                currentDate.getUTCMonth(),
                currentDate.getUTCDate(),
                currentDate.getUTCHours(),
                currentDate.getUTCMinutes(),
                currentDate.getUTCSeconds()
            ) :
            currentDate;
        return moment.utc(utcDate.toString()).toDate();
    }

}

export const toLocalDate = (isoDateString: string) => {
    var stillUtc = moment.utc(isoDateString).toDate();
    // var clientDate = moment.utc(new Date(stillUtc).toString()).local().toDate();
    // return clientDate; 
    return moment(stillUtc).local().toDate();

}

export const toLocalDateString = (isoDateString: string, format?: string) => {
    console.log("CandidateUtcDate", isoDateString)
    var stillUtc = moment.utc(isoDateString).toDate();
    return moment(stillUtc).local().format(format ? format : 'YYYY-MM-DD HH:mm:ss');
}

/**
 * Removes extra white spaces from a given string by replacing consecutive spaces
 * with a single space and trimming leading and trailing spaces.
 *
 * @param input - The input string with potential extra white spaces.
 * @returns A string with consecutive spaces reduced to a single space and
 *          leading/trailing spaces removed.
 */
export const removeExtraWhiteSpace = (input: string): string => {
    return input?.replace(/\s{2,}/g, ' ')?.trim() || '';
};

/**
 * Truncates an array of strings and appends a count of remaining elements if necessary.
 *
 * @param data - The array of strings to be truncated.
 * @param totalLength - The maximum length of the truncated array.
 * @returns An array of truncated strings with an optional count indicator.
 */
export const truncateArrayAndAddCount = (data: string[], totalLength: number): string[] => {
    const updatedData: string[] = [];

    if (data && data.length > 0) {
        if (data.length > totalLength) {
            const slicedArray = data.slice(0, totalLength);
            const remainingArray = data.length - slicedArray.length;
            updatedData.push(...slicedArray, `+${remainingArray} more`);
        } else {
            updatedData.push(...data);
        }
    }

    return updatedData;
};