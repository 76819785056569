import React from "react";
import { Flex, Text, Icon, Menu, MenuButton } from "@chakra-ui/react";
import Link from "./Link";
import VestingScheduleDialogBox from "../VestingScheduleBox/VestingScheduleDialogBox";

interface NavItemProps {
  icon: any;
  title: string;
  active?: any;
  path: string;
}

const NavItem: React.FC<NavItemProps> = (props) => {
  return (
    <Flex mt={2} flexDir="column" w="100%" alignItems={"center"}>
      <Menu placement="right">
        <Link
          backgroundColor={props.active && "#c1ebee"}
          p={3}
          borderRadius={8}
          href={props.path}
          _hover={{ textDecor: "none", backgroundColor: "#AEC8CA" }}
          w="100%"
        >
          <MenuButton w="100%">
            <Flex ml={5}>
              <Icon
                as={props.icon}
                fontSize="xl"
                color={props.active ? "#82AAAD" : "gray.500"}
              />
              <Text ml={5} display={"flex"}>
                {props.title}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
};

export default NavItem;
