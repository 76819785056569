import { Button, chakra } from "@chakra-ui/react";

const ButtonPrimary = chakra(Button, {
  baseStyle: {
    bg: "secondary",
    color: "white",
    fontWeight: 600,
    fontSize: "14px",
    px: "8",
    _hover: {
      opacity: 0.6,
      bg: "secondary",
    },
    _focus: {
      boxShadow: "none",
    },
  },
});

const ButtonOutline = chakra(ButtonPrimary, {
  baseStyle: {
    bg: "transparent",
    color: "black",
    p: 0,
    _hover: {
      opacity: 0.6,
      bg: "transparent",
    },
  },
});

const ButtonWallet = chakra(Button, {
  baseStyle: {
    color: "gray",
    p: 0,
    _hover: {
      opacity: 0.6,
    },
    _focus: {
      boxShadow: "none",
    },
  },
});

export default ButtonPrimary;
export { ButtonOutline, ButtonWallet };
