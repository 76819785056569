import commonService from "@/components/services/CommonService";
import usersService from "@/components/services/UserService";
import Stack from "@/components/shared/Stack";
import ErrorMessageYup from "@/components/shared/error-message/ErrorMessageWrapper";
import Input from "@/components/shared/form-control/Input";
import PageLoader from "@/components/shared/page-loader/PageLoader";
import { Body1, Caption2 } from "@/components/shared/typography";
import { useStyles } from "@/core/form-error";
import { encryptToHex } from "@/core/utility/common";
import { InputGroup, InputRightElement, VStack, useToast } from "@chakra-ui/react";
import { Organization, Users } from "@prisma/client";
import { Field, FieldProps, Form, Formik } from "formik";
import _ from "lodash";
import { FC, RefObject, useState } from "react";
import ButtonPrimary from "../shared/button/ButtonPrimary";
import { useRouter } from "next/router";
import { homePath } from "data/routes";
import Link from "../shared/Link";
import Logo from "../shared/Logo";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { changePasswordValidation } from "@/core/form-validaation/profile";
import { changePassword } from "@/core/utility/emailService/templates";
import { getEmailTemplate } from "@/core/utility/emailService";
import { sendEmail } from "../services/EmailService";
import { format } from "date-fns";
import useUser from "hooks/useUser";
import { useDisconnect } from "wagmi";
import NoteBox from "../shared/alertbox-wrapper/NoteBox";
import orgService from "../services/OrgService";


type ChangePasswordDetails = {
    previousPassword: string,
    password: string,
    confirmPassword: string,
};

interface ChangeProfilePasswordProps {
    email: string;
    previousPassword?: string;
    roleType: number;
    id: number;
    btnSubmitRef: RefObject<HTMLButtonElement>;
    onClose?: () => void;
}

const ChangeProfilePassword: FC<ChangeProfilePasswordProps> = (props) => {
    const { email, previousPassword, roleType, id, btnSubmitRef, onClose } = props;

    const [initialValues, setInitialValues] = useState({} as ChangePasswordDetails);
    const [userHome, setUserHome] = useState(homePath.root);
    const [passwordType, setPasswordType] = useState("password");
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const toast = useToast();
    const router = useRouter();

    const { mutateUser, user } = useUser();
    const { disconnectAsync, disconnect } = useDisconnect();

    const SUPPORT_TEAM_EMAIL = process.env.NEXT_PUBLIC_SUPPORT_TEAM_EMAIL;
    let API_BASE_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

    if (typeof window !== "undefined") {
        // Client-side-only code
        API_BASE_URL = window.location.origin;
    }

    const handleSubmit = async (values: ChangePasswordDetails) => {
        if (!values || !email) return;

        let encPassword = await encryptToHex(values?.password ?? "");
        if (roleType > 1 && encPassword) {
            setShowSpinner(true);
            await saveCandidateProfile(email, id, encPassword);
            return;
        }
        if (roleType == 1 && encPassword) {
            setShowSpinner(true);
            await saveOrganizationProfile(email, id, encPassword);
            return;
        }
    }

    //Method to save new password for the candidate
    const saveCandidateProfile = async (email: string, id: number, newPassword: string) => {

        const user = {
            Id: id,
            Email: email,
            Password: newPassword
        } as Users;
        if (user) {
            let respdata = await usersService.CreateUser('token', user);
            if (respdata && respdata?.status === 200) {

                let candidate = respdata?.data as Users;
                let fullName = `${candidate?.FirstName} ${candidate?.LastName ?? ""}`;
                //send email for change passowrd
                await sendEmailForPasswordChange(fullName, user.Email ?? "");

                toast({
                    title: "Password changed successfully",
                    status: "success",
                    duration: 3000,
                    position: 'bottom-right',
                    variant: 'left-accent',
                    isClosable: true
                });
                handleClear();
                handleLogout();
                onClose?.();
            }
            else {
                //setIsError(true);
                toast({
                    title: "Unable to change password due to technical issue.",
                    status: "error",
                    duration: 3000,
                    position: 'bottom-right',
                    variant: 'left-accent',
                    isClosable: true
                });
                handleClear();
            }
        } else {
            setShowSpinner(false);
        }
    }

    const saveOrganizationProfile = async (email: string, id: number, newPassword: string) => {

        const org = {
            Id: id,
            Email: email,
            Password: newPassword
        } as Organization;
        if (org) {
            let respdata = await orgService.CreateOrg('token', org);
            if (respdata && respdata?.status === 200) {

                let candidate = respdata?.data as Users;
                let fullName = `${candidate?.FirstName} ${candidate?.LastName ?? ""}`;
                //send email for change passowrd
                await sendEmailForPasswordChange(fullName, org.Email ?? "");

                toast({
                    title: "Password changed successfully",
                    status: "success",
                    duration: 3000,
                    position: 'bottom-right',
                    variant: 'left-accent',
                    isClosable: true
                });
                handleClear();
                handleLogout();
                onClose?.();
            }
            else {
                //setIsError(true);
                toast({
                    title: "Unable to change password due to technical issue.",
                    status: "error",
                    duration: 3000,
                    position: 'bottom-right',
                    variant: 'left-accent',
                    isClosable: true
                });
                handleClear();
            }
        } else {
            setShowSpinner(false);
        }
    }

    const sendEmailForPasswordChange = async (name: string, email: string) => {
        //Send successfull change password mail
        let mailOptions = {
            to: email,
            subject: changePassword.subject,
            html: ""
        };

        let currentDate = format(new Date(), 'dd MMM yyyy');
        let templateData = {
            name: name,
            userEmail: email,
            contactEmail: SUPPORT_TEAM_EMAIL || "",
            currentDate: currentDate,
        }

        mailOptions.html = getEmailTemplate(changePassword.body, templateData);

        let resp = await sendEmail(mailOptions);
    }

    const handleClear = () => {
        setInitialValues({
            previousPassword: "",
            password: "",
            confirmPassword: "",
        } as ChangePasswordDetails);
        setPasswordType('password');
        setShowSpinner(false);
    }



    const handleLogout = async () => {
        const resp = await fetch('/api/logout');
        const userData = await resp.json();
        mutateUser(userData);
        disconnect();
        router.push("/login");
    };

    return (
        <>
            <NoteBox
                isOpen={isConfirmDialogOpen}
                setIsOpen={setIsConfirmDialogOpen}
                onSuccessAction={async () => {
                    btnSubmitRef?.current?.click();
                }}
                headerText={"Delete Education"}
                bodyText={"Are you sure you want to delete the selected education history?"}
                buttonText={"Ok"} />
            <PageLoader show={showSpinner} />
            <Stack spacing={4} w="full" p="2" marginInlineStart={"0px !important"}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={changePasswordValidation}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form style={{ width: "100%" }}>
                            <Stack justifyContent={"center"} display={"none"} >
                                <Link href={userHome}>
                                    <Logo src="/images/nested.png" />
                                </Link>
                            </Stack>
                            <Caption2 color={"gray.800"} fontSize={"14px"}>Note: Since you are changing your password, your current session will be reset and you will be logged out. Please login again. </Caption2>
                            <Caption2>* Indicates required</Caption2>
                            <VStack mt={4} spacing={4} color="black" align="stretch" w="full">
                                <Stack direction="column" w="full">
                                    <Field name="password">
                                        {({ field, meta }: FieldProps) => (
                                            <InputGroup>
                                                <InputRightElement pointerEvents="all"
                                                    onClick={() => {
                                                        if (passwordType === "password") {
                                                            setPasswordType("text");
                                                        }
                                                        else {
                                                            setPasswordType("password");
                                                        }
                                                    }}>
                                                    {passwordType === "password" ? (
                                                        <AiOutlineEyeInvisible color="gray.300" size={16} />
                                                    ) : (
                                                        <AiOutlineEye color="gray.300" size={16} />
                                                    )}
                                                </InputRightElement>
                                                <Input
                                                    {...field}
                                                    borderColor={meta.error ? useStyles.errorColor : useStyles.exactColor}
                                                    borderWidth="2px"
                                                    placeholder="New Password*"
                                                    type={passwordType}
                                                    value={initialValues?.password}
                                                    onChange={(e) => {
                                                        setInitialValues(prevState => ({
                                                            ...prevState, password: e?.target.value
                                                        }));
                                                    }}
                                                />
                                            </InputGroup>
                                        )}
                                    </Field>
                                    <ErrorMessageYup name="password" />
                                </Stack>
                                <Stack direction="column" w="full">
                                    <Field
                                        name="confirmPassword">
                                        {({ field, meta }: FieldProps) => (
                                            <Input
                                                {...field}
                                                borderWidth="2px"
                                                placeholder="Confirm Password*"
                                                type="password"
                                                borderColor={meta.error ? useStyles.errorColor : useStyles.exactColor}
                                                value={initialValues?.confirmPassword}
                                                onChange={(e) =>
                                                    setInitialValues(prevState => ({
                                                        ...prevState, confirmPassword: e.target.value
                                                    }))
                                                }
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessageYup name="confirmPassword" />
                                </Stack>
                                <Stack direction="column" w="auto" alignSelf={"center"}>
                                    <ButtonPrimary ref={btnSubmitRef} w="186px" type="submit" display={"none"}>Ok</ButtonPrimary>
                                </Stack>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </>
    );
};
export default ChangeProfilePassword;