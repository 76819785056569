import { Button, useToast, VStack } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import Modal from ".";
import { useModalContext } from "../../../hooks/useContext";
import Stack from "../Stack";
import ChangeProfilePassword from "@/components/profile/ChangeProfilePassword";
import useUserDetails from "hooks/useUserDetails";
import { Caption2 } from "../typography";

interface ChangeProfilePasswordModalProps {
  isClosable?: boolean;
  isDefaultPassword?: boolean;
}

const ChangeProfilePasswordModal: FC<ChangeProfilePasswordModalProps> = (props) => {
  const { isClosable = true, isDefaultPassword = false } = props;

  const { changeProfilePasswordModal } = useModalContext();
  const { isOpen, onClose } = changeProfilePasswordModal;
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [id, setId] = useState(0);
  const [roleType, setRoleType] = useState(0);
  const { user: currentUser } = useUserDetails();

  useEffect(() => {
    if (currentUser && currentUser?.userId && currentUser?.userId > 0) {
      setEmail(currentUser?.emailId ?? "");
      setId(currentUser?.userId);
      setRoleType(currentUser?.roleId);
    }
  }, [currentUser]);

  const handleCloseModal = () => {
    if (isClosable) {
      onClose();
    } else {
      toast({
        title: "Please consider updating your default password to ensure heightened security measures!",
        status: "warning",
        duration: 3000,
        position: "bottom-right",
        variant: "left-accent",
        isClosable: true,
      });
    }
  };

  const handleOkModal = () => {
    buttonSubmitRef?.current?.click();
  };

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Modal
        headerProps={{
          textAlign: "center",
        }}
        modalProps={{
          size: "xl",
          closeOnOverlayClick: false
        }}
        contentProps={{
          containerProps: {
            // "zIndex": 99999
          }
        }}
        header="Change Profile Password"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <VStack spacing={6} align="start">
          {isDefaultPassword && (
            <Caption2 p={2} mb="-10" color={"gray.800"} fontSize={"14px"}>Note: Kindly update your default password for enhanced security. </Caption2>
          )}
          <ChangeProfilePassword btnSubmitRef={buttonSubmitRef} email={email} roleType={roleType} id={id} onClose={onClose} />
        </VStack>
        <VStack spacing={6} align="start">
          <Stack flex={1} direction={"row"} justifyContent="center" w="full">
            <Button variant="solid" colorScheme="blue" onClick={handleOkModal}>
              Ok
            </Button>
          </Stack>
        </VStack>

      </Modal>
    </>
  );
};
export default ChangeProfilePasswordModal;
