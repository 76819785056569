import {
  Box,
  Collapse,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { BiMenuAltLeft } from "react-icons/bi";
import { IoMdClose, IoMdLock, IoMdLogOut } from "react-icons/io";
import { ShowProps } from "@chakra-ui/media-query";
import dynamic from "next/dynamic";
import { ComponentType, useEffect, useState } from "react";

import {
  company_routes,
  public_routes,
  employee_routes,
  admin_routes,
  platform_support_routes,
  platform_manager_routes,
  homePath,
  footerPaths,
} from "data/routes";
import Container from "../Container";
import Link from "../Link";
import Logo from "../Logo";
import Navigation from "./Navigation";
import NotificationMenu from "../menu/NotificationMenu";
import UserMenu from "../menu/UserMenu";
import { useAccount, useDisconnect } from "wagmi";
import { CandidateProfile } from "core/models/candidate";
import usersService from "@/services/UserService";
import ButtonPrimary from "../button/ButtonPrimary";
import { useRouter } from "next/router";
import useUser from "hooks/useUser";
import { AiOutlineUserAdd } from "react-icons/ai";
import useUserDetails from "hooks/useUserDetails";
import WalletMenu from "../menu/WalletMenu";

const Show = dynamic(
  () => import("@chakra-ui/media-query").then((mod) => mod.Show) as any,
  {
    ssr: false,
  }
) as ComponentType<ShowProps>;

interface HeaderProps {
  routeType:
    | "public"
    | "company"
    | "employee"
    | "admin"
    | "platform-support"
    | "platform-manager"
    | "operation";
}

const Header: React.FC<HeaderProps> = (props) => {
  const { routeType } = props;
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [myType, setMyType] = useState(0);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [candidateProfile, setCandidateProfile] = useState<CandidateProfile>();
  const [userHome, setUserHome] = useState(homePath.root);
  const { address } = useAccount();
  const { mutateUser, user } = useUser();
  const { user: currentUser } = useUserDetails();
  const { disconnectAsync, disconnect } = useDisconnect();

  let routes = [] as any;
  switch (routeType) {
    case "company":
      routes = company_routes.map((d) => ({
        name: d.name,
        href: d.href,
        isMenu: d.isMenu,
        menuName: d.menuName,
        index: d.index,
      }));
      break;
    case "employee":
      routes = employee_routes.map((d) => ({
        name: d.name,
        href: d.href,
        isLink: d.isLink,
      }));
      break;
    case "admin":
      routes = admin_routes.map((d) => ({
        name: d.name,
        href: d.href,
      }));
      break;
    case "platform-support":
      routes = platform_support_routes.map((d) => ({
        name: d.name,
        href: d.href,
      }));
      break;
    case "platform-manager":
      routes = platform_manager_routes.map((d) => ({
        name: d.name,
        href: d.href,
      }));
      break;
    default:
      routes = [];
      break;
  }

  useEffect(() => {
    const loadWalletData = async () => {
      if (
        currentUser &&
        currentUser?.isLoggedIn &&
        currentUser?.userId &&
        currentUser?.userId > 0
      ) {
        setMyType(currentUser.roleId);
        setIsRegistered(currentUser.isRegistered);
        setIsWalletConnected(true);
        setUserHome(() => {
          switch (currentUser.roleId) {
            case 1:
              return homePath.orgHome;
            case 2:
              return homePath.candidateHome;
            case 3:
            case 4:
            case 5:
              return homePath.supportHome;
            default:
              return homePath.root;
          }
        });

        if (currentUser.roleId == 2) {
          let resp = await usersService.GetUserFilteredProfileByWalletAddress(
            "token",
            { emailId: currentUser?.emailId, showExperience: true }
          );
          Promise.any([resp]).then(async (data: any) => {
            if (data && data?.status == 200) {
              const candidate = data.data as CandidateProfile;
              setCandidateProfile(candidate);
            }
          });
        }
      } else {
        setMyType(-1);
        setIsWalletConnected(false);
        setUserHome(homePath.root);
      }
    };
    loadWalletData();
  }, [currentUser, router.asPath]);

  const handleLogin = () => {
    router.push("/login");
  };

  const handleRegister = () => {
    router.push("/register/candidate");
  };

  const handleLogout = async (e: any) => {
    const resp = await fetch("/api/logout");
    const userData = await resp.json();
    mutateUser(userData);
    disconnect();
    router.push("/login");
  };

  return (
    <Box bg="#264252" as="header" pos="relative">
      <Container py="4" px="2">
        <HStack
          //w="max-content"
          w="full"
        >
          <Show below="lg">
            <IconButton
              aria-label="Toggle Menu"
              variant="unstyled"
              icon={
                isOpen ? <IoMdClose size={28} /> : <BiMenuAltLeft size={30} />
              }
              color="white"
              onClick={() => setIsOpen(!isOpen)}
            />
          </Show>
          <HStack w="max-content">
            <Link href={userHome}>
              <Logo />
            </Link>
          </HStack>
          <Spacer />
          <HStack
            w="max-content"
            align={"flex-end"}
            //minW="-webkit-fit-content"
            justifyContent="center"
            alignItems={"center"}
          >
            <Show above="lg">
              <Navigation routes={routes} />
            </Show>

            <HStack minW="-webkit-fit-content" spacing={2}>
              {currentUser &&
                currentUser?.userId &&
                currentUser?.userId > 0 &&
                myType &&
                myType == 1 && <WalletMenu />}
              {isWalletConnected && myType && myType > 10 && isRegistered && (
                <NotificationMenu />
              )}
              {currentUser && currentUser.isLoggedIn && myType && (
                <UserMenu
                  myType={myType}
                  candidateProfile={candidateProfile}
                  isRegistered={isRegistered}
                />
              )}
              {!currentUser?.isLoggedIn && (
                <Show above="lg">
                  <ButtonPrimary
                    _focusVisible={{
                      border: "2px solid",
                      borderColor: "blue.500",
                      boxShadow: "0 0 0 1px blue.500",
                    }}
                    onClick={handleLogin}
                    leftIcon={<IoMdLock />}
                  >
                    Login
                  </ButtonPrimary>
                  <ButtonPrimary
                    _focusVisible={{
                      border: "2px solid",
                      borderColor: "blue.500",
                      boxShadow: "0 0 0 1px blue.500",
                    }}
                    onClick={handleRegister}
                    leftIcon={<AiOutlineUserAdd />}
                  >
                    Register
                  </ButtonPrimary>
                </Show>
              )}
              {currentUser && currentUser.isLoggedIn && (
                <Tooltip label={"Log Out"} placement="bottom" hasArrow>
                  <HStack spacing={0} mr="10px" cursor={"pointer"}>
                    <Icon
                      rounded="full"
                      as={IoMdLogOut}
                      boxSize="30px"
                      color="white"
                      bg={isOpen ? "secondary" : "transparent"}
                      onClick={handleLogout}
                    />
                  </HStack>
                </Tooltip>
              )}
            </HStack>
          </HStack>
        </HStack>
        <Show below="lg">
          <Collapse in={isOpen}>
            <Box ml="8" py="4">
              <Navigation routes={routes} direction="column" />
            </Box>
            {!currentUser?.isLoggedIn && (
              <>
                <ButtonPrimary
                  _focusVisible={{
                    border: "2px solid",
                    borderColor: "blue.500",
                    boxShadow: "0 0 0 1px blue.500",
                  }}
                  mr="2"
                  onClick={handleLogin}
                  leftIcon={<IoMdLock />}
                >
                  Login
                </ButtonPrimary>
                <ButtonPrimary
                  _focusVisible={{
                    border: "2px solid",
                    borderColor: "blue.500",
                    boxShadow: "0 0 0 1px blue.500",
                  }}
                  onClick={handleRegister}
                  leftIcon={<AiOutlineUserAdd />}
                >
                  Register
                </ButtonPrimary>
              </>
            )}
          </Collapse>
        </Show>
      </Container>
    </Box>
  );
};
export default Header;
