import { ErrorMessage } from "formik";
import { FC, ReactChild, ReactFragment, ReactPortal } from "react";

const useStyles = {
   error: {
      color: 'red',
      padding: 0,
      fontSize: '0.75rem',
      lineHeight: 1,
      letterSpacing: '0.00938em'
   },
   errorYup: {
      color: 'red',
      padding: 0,
      fontSize: '0.75rem',
      lineHeight: 1,
      letterSpacing: '0.00938em'
    },
};

const ErrorMessageWrapper = (msg: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined) => {

   return (
      <div style={useStyles.error}>
         {msg}
      </div>
   )
}

interface ErrorMessageProps {
   name: string;
   style?: string;
}

const ErrorMessageYup: FC<ErrorMessageProps> = (props) => {

   const { name, style } = props;

   return (
      <>
         <ErrorMessage name={name}>
            {ErrorMessageWrapper}
         </ErrorMessage>
      </>
   );
};
export default ErrorMessageYup;