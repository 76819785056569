
import { Attachment, AttachmentLike, TextEncoding } from "nodemailer/lib/mailer";
import { Readable } from "nodemailer/lib/xoauth2";
import * as nodemailer from 'nodemailer';
import Handlebars from 'handlebars';

const MAIL_HOST = process.env.NODEMAILER_HOST;
const MAIL_PORT = process.env.NODEMAILER_PORT;
const MAIL_USER = process.env.NODEMAILER_USER;
const MAIL_PASSWORD = process.env.NODEMAILER_PASS;
const MAIL_SENDER_NAME = process.env.NODEMAILER_SENDER_NAME;
const MAIL_REPLY_TO = process.env.NODEMAILER_SENDER;

const transporter = nodemailer.createTransport({
    host: MAIL_HOST,
    port: MAIL_PORT ? parseInt(MAIL_PORT) : 587,
    secure: false,
    tls: {
        rejectUnauthorized: true,
        minVersion: "TLSv1.2"
    },
    auth: {
        user: MAIL_USER,
        pass: MAIL_PASSWORD,
    },
});


export interface MailOptionsProps {
    /** The e-mail address of the sender. All e-mail addresses can be plain 'sender@server.com' or formatted 'Sender Name <sender@server.com>' */
    from?: string;
    /** An e-mail address that will appear on the Sender: field */
    sender?: string;
    /** Comma separated list or an array of recipients e-mail addresses that will appear on the To: field */
    to?: string;
    /** Comma separated list or an array of e-mail addresses that will appear on the Reply-To: field */
    replyTo?: string;
    /** The subject of the e-mail */
    subject?: string;
    /** The plaintext version of the message */
    text?: string | Buffer | Readable | AttachmentLike;
    /** The HTML version of the message */
    html?: string | Buffer | Readable | AttachmentLike;
    /** Apple Watch specific HTML version of the message, same usage as with text and html */
    watchHtml?: string | Buffer | Readable | AttachmentLike;
    /** An array of attachment objects */
    attachments?: Attachment[];
    /** optional Date value, current UTC string will be used if not set */
    date?: Date | string;
    /** optional transfer encoding for the textual parts */
    encoding?: string;
    /** set explicitly which encoding to use for text parts (quoted-printable or base64). If not set then encoding is detected from text content (mostly ascii means quoted-printable, otherwise base64) */
    textEncoding?: TextEncoding;
    /**set the mail priority */
    priority?: "high" | "normal" | "low";
    /** if set to true then converts data:images in the HTML content of message to embedded attachments */
    attachDataUrls?: boolean;
}

export const sendMail = async (mailOptions: MailOptionsProps) => {
    mailOptions.from = `${mailOptions.sender || MAIL_SENDER_NAME} <${MAIL_REPLY_TO}>`;
    mailOptions.replyTo = mailOptions.replyTo || MAIL_REPLY_TO;

    return new Promise((resolve) => {
        transporter.sendMail(mailOptions, (error, info) => {
            if (error) {
                console.error(`[${new Date().toISOString()}] Failed to send email: ${error}`);
                resolve(error);
            } else {
                console.log(`[${new Date().toISOString()}] Email sent: ${info.response}`);
                resolve(info);
            }
        });
    });
};

export const getEmailTemplate = (body: string, values: any) => {
    const template = Handlebars.compile(body);
    return template(values);
}