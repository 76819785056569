import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { HStack, Icon } from "@chakra-ui/react";
import { useRouter } from "next/router";

import { FC, useEffect, useState} from "react";
import { IoChevronDownOutline, IoPersonCircle } from "react-icons/io5";
import { CandidateProfile } from "../../../core/models/candidate";

import { Caption1, Caption2 } from "../typography";

interface OrganizationMenuProps {
  myType?: number;
  candidateProfile?: CandidateProfile;
  routes: {
    name: string;
    href: string;
    isMenu: boolean;
    index: number;
    menuName?: string;
  }[];
}

const OrganizationMenu: FC<OrganizationMenuProps> = (props) => {
  const { myType, candidateProfile, routes } = props;
  const router = useRouter();
  const [checkedIsRecruiter, setCheckedIsRecruiter] = useState(false);

  useEffect(() => {

    if (candidateProfile) {
      var isCandidateRecruiter = candidateProfile.experience.filter(x => x.isRecruiter);
      if (isCandidateRecruiter && isCandidateRecruiter.length > 0) {
        setCheckedIsRecruiter(true);
      }
      else {
        setCheckedIsRecruiter(false);
      }
    }
  }, [candidateProfile])

  const handleLogout = () => { };
  const handleAccountSetting = () => { };

  const handleRecruiterAccount = () => { 
    router.push("/profile/recruiter");
  };

  return (
    <Menu placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton>
            <HStack spacing={0}>
              <Caption1
                color={isOpen ? "white" : "gray"}
                _hover={{
                  color: "white",
                }}
                _focus={{
                  color: "white",
                }}
                fontWeight="600"
              >
                {routes[0]?.menuName}
              </Caption1>
              <IoChevronDownOutline color="lightgray" size={16} />
            </HStack>    
          </MenuButton>
          <MenuList
            overflow="hidden"
            rounded="2xl"
            minW="160px"
            p="0"
            boxShadow="2xl"
          >
            {
              routes.map((route) => {
                return (
                  <>
                    <MenuItem p="3" onClick={() => router.push(route.href)} key={route.name}>
                      <Caption2 fontWeight="600" color="black">
                      {route.name}
                      </Caption2>
                    </MenuItem>
                    <MenuDivider my="0" />
                  </>
                )
              })
            }
          </MenuList>
        </>
      )}
    </Menu>
  );
};
export default OrganizationMenu;
