import { chakra } from "@chakra-ui/react";
import { Input as _Input, InputProps } from "@chakra-ui/input";

const Input = chakra<"input", InputProps>(_Input as any, {
  baseStyle: {
    fontSize: "13px",
    bg: "white",
    _placeholder: {
      color: "gray.500",
    },
  },
});

Input.id = "Input";
Input.displayName = "Input";

export default Input;
