import { Stack as _Stack, StackProps } from "@chakra-ui/layout";
import { FC } from "react";

const Stack: FC<StackProps> = (props) => {
  const {
    direction = {
      base: "column",
      lg: "row",
    },
    ...rest
  } = props;

  return <_Stack {...rest} direction={direction} />;
};

export default Stack;
