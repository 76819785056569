import {
  TabList,
  Tabs as _Tabs,
  Tab,
  TabPanels,
  TabPanel as _TabPanel,
  TabListProps,
  TabProps,
  TabsProps as _TabsProps
} from "@chakra-ui/tabs";
import { Flex, chakra } from "@chakra-ui/react";
import { FC, ReactElement, ReactNode } from "react";

const TabPanel = chakra(_TabPanel, {
  baseStyle: {
    p: 0,
    mt: "8",
  },
});

interface TabsProps extends TabListProps {
  rightHeader?: ReactElement;
  tabList: ReactNode[];
  tabProps?: TabProps;
  mainTabsProps?: Omit<_TabsProps, 'children'>;
}

const Tabs: FC<TabsProps> = (props) => {
  const { rightHeader, tabList, children, tabProps, mainTabsProps, ...rest } = props;
  return (
    <_Tabs variant="solid-rounded" {...mainTabsProps}>
      <Flex justify="space-between" wrap="wrap" gap={4}>
        <TabList
          fontSize="11px"
          flex={1}
          bg="lightgray"
          display="flex"
          p="1"
          alignItems="stretch"
          rounded="full"
          color="black"
          pos="relative"
          fontWeight="600"
          {...rest}
        >
          {tabList.map((tab, index) => (
            <Tab
              key={`${index}-${tab?.toString()}`}
              fontSize="12px"
              flex={1}
              _selected={{
                bg: "white",
                boxShadow: "lg",
              }}
              {...tabProps}
            >
              {tab}
            </Tab>
          ))}
        </TabList>
        {rightHeader}
      </Flex>
      {children}
    </_Tabs>
  );
};

export { Tabs, TabPanel, TabPanels };
