import { FC } from "react";
import NextLink  from "next/link";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { Url,UrlObject } from "url";

interface NextLinkProps extends LinkProps {
  href2?: Url
}

const Link: FC<NextLinkProps> = (props) => {
  const { href2, href, ...rest } = props;
  return (
    <NextLink
      href={href2 && href2 !== undefined ? href2 : {
        pathname: href,
      }}
      passHref
    >
      <ChakraLink _hover={{}} {...rest} />
    </NextLink>
  );
};

export default Link;
