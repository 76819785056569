interface templateType {
  subject: string;
  body: string;
}

/**
 * Generates an email template to welcome a new user to Nested Club.
 *
 * @param {string} name - The name of the user receiving the email.
 * @param {string} contactEmail - The support team's contact email.
 * @returns An email template object with subject and body for the welcome email.
 */
export const welcome: templateType = {
  subject: "Welcome to Nested Club!",
  body: `<p>Dear {{name}},</p>
<br />
<p>Welcome to Nested Club! We are thrilled to have you join us on our mission to empower individuals in controlling and monetizing their professional profiles. As a member of Nested Club, you will have the opportunity to take complete ownership of your professional profile and monetize it through our various programs.</p> 
<p>To get started, it is essential that you complete the KYC (Know Your Customer) and employment verification process. This step is crucial in granting you full access to the range of benefits offered to Nested Club members.</p>
<p>Below videos might help you with KYC and employment verification process:</p>
<p><a href="https://youtu.be/5krnUWKHPM0" target="_blank">How to do KYC in Nested Club?</a></p>
<p>Join our ever-growing community on 
<a target="_blank" style= "margin-right: 2rem;" href="https://twitter.com/NestedClub"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 16 16" aria-label="Navigate to Twitter" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: -2px;"><path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path></svg>Twitter</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.linkedin.com/company/90594042/"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 1024 1024" aria-label="Navigate to Linkedin" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="stroke: rgb(0, 143, 148); stroke-width: 5; margin-bottom: -2px;"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z"></path></svg>LinkedIn</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.instagram.com/nestedclub/"><svg fill="#008F94" viewBox="0 0 24 24" role="img" aria-label="Navigate to Instagram" focusable="false" style="width: 1rem; height: 1rem;"><path d="m23.09.91c-.61-.61-1.33-.91-2.17-.91h-17.84c-.85 0-1.57.3-2.17.91s-.91 1.33-.91 2.17v17.84c0 .85.3 1.57.91 2.17s1.33.91 2.17.91h17.84c.85 0 1.57-.3 2.17-.91s.91-1.33.91-2.17v-17.84c0-.85-.3-1.57-.91-2.17zm-14.48 7.74c.94-.91 2.08-1.37 3.4-1.37 1.33 0 2.47.46 3.41 1.37s1.41 2.01 1.41 3.3-.47 2.39-1.41 3.3-2.08 1.37-3.41 1.37c-1.32 0-2.46-.46-3.4-1.37s-1.41-2.01-1.41-3.3.47-2.39 1.41-3.3zm12.66 11.63c0 .27-.09.5-.28.68a.92.92 0 0 1 -.67.28h-16.7a.93.93 0 0 1 -.68-.28.92.92 0 0 1 -.27-.68v-10.13h2.2a6.74 6.74 0 0 0 -.31 2.05c0 2 .73 3.71 2.19 5.12s3.21 2.12 5.27 2.12a7.5 7.5 0 0 0 3.75-.97 7.29 7.29 0 0 0 2.72-2.63 6.93 6.93 0 0 0 1-3.63c0-.71-.11-1.39-.31-2.05h2.11v10.12zm0-13.95c0 .3-.11.56-.31.77a1.05 1.05 0 0 1 -.77.31h-2.72c-.3 0-.56-.11-.77-.31a1.05 1.05 0 0 1 -.31-.77v-2.58c0-.29.11-.54.31-.76s.47-.32.77-.32h2.72c.3 0 .56.11.77.32s.31.47.31.76z" fill-rule="evenodd"></path></svg>Instagram</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.facebook.com/people/Nested-Club/100090953042366/"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 16 16" aria-label="Navigate to Facebook" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path></svg>Facebook</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.youtube.com/channel/UCIOQ3pgKCThNbTHWiljYJlw"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 16 16" aria-label="Navigate to Youtube" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path></svg>YouTube</a>
to stay up-to-date with the latest news and feel free to reach out to us with any questions you may have.</p>
<p>Thank you once again for choosing Nested Club! We are excited to support you in expanding your professional network and support you monetize your profile.</p>
<p>Should you require any assistance, please don't hesitate to contact us at {{contactEmail}} or through our social media channels.</p>
<p>We are eager to witness the incredible possibilities that await you and your Nested Club account.</p>
<p>Looking forward to your profile monetization and professional growth journey with Nested Club!</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template requesting KYC update for Nested Club for candidate.
 *
 * @param {string} name - The name of the user receiving the email.
 * @returns An email template object with subject and body for the KYC update request.
 */
export const requestKYC: templateType = {
  subject: "Requesting KYC update to Nested Club.",
  body: `<p>Dear {{name}},</p> 
<br />
<p>At the outset, we extend our thanks for registering with Nested!</p>
<p>This is a gentle reminder to complete your KYC so that you can reap the benefits of the Nested platform.</p>
<p><b>Benefits of completing KYC</b></p>
<ol>
<li>Own and Control your profile</li>
<li>Get verified by previous employers and earn credits</li>
<li>Monitise your profile</li>
<li>Network with employers and peers</li>
<li>Earn while you network and hunt for jobs</li>
</ol>
<p><b>How to complete KYC for Nested</b></p>
<p>It’s a very simple process. Here is a link that explains it <a href="https://youtu.be/5krnUWKHPM0" target="_blank">How to do KYC in Nested Club?</a></p>
<br />
<p>Thank you,</p> 
<p>Team Nested</p>`,
};

/**
 * Generates an email template for congratulating users on completing KYC for Nested Club.
 *
 * @param {string} name - The name of the user receiving the email.
 * @returns An email template object with subject and body for the KYC completion congratulations.
 */
export const kycCompletion: templateType = {
  subject: "Congratulations on completing your KYC for Nested",
  body: `<p>Dear {{name}},</p>
<br />
<p>Congratulations on successfully completing your KYC for Nested.</p>
<p>Please get started on your journey into the most remarkable platform for professional networking. You can now proceed with requesting employer verification from all your previous employers; start your job hunting and networking with peers. Earn credits and monitise all along the way!</p>
<p>Do not hesitate to reach out to us if you need any assistance.</p>
<br />
<p>Wishing you all the best.</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for notifying the support team about employment verification for a candidate.
 *
 * @param {string} candidateName - The name of the candidate requesting verification.
 * @param {string} idNumber - The ID number of the candidate.
 * @param {string} employerName - The name of the employer for verification.
 * @returns An email template object with subject and body for employment verification notification.
 */
export const candidateExpApprovalToSupport: templateType = {
  subject: "Employment Verification for {{candidateName}}",
  body: `<p>Dear Support team</p>
<br />
<p>Our candidate named {{candidateName}} with email id- {{candidateEmail}} has requested an employer verification for {{employerName}}. The employer is not registered with us yet. You are requested to reach out and get the verification done and try and also get the company onboarded with Nested at the earliest.</p>
<br />
<p>Thank you</p>`,
};

/**
 * Generates an email template for notifying a candidate about the approval of employment verification.
 *
 * @param {string} userName - The name of the user (candidate) receiving the notification.
 * @param {string} employerName - The name of the employer who confirmed the employment history.
 * @returns An email template object with subject and body for employment verification approval notification.
 */
export const candidateExpApproved: templateType = {
  subject: "Employment Verification from {{employerName}}",
  body: `<p>Dear {{userName}},</p>
  <br />
  <p>We're excited to let you know that {{employerName}} has confirmed your employment history on Nested Club! This is an important step in ensuring the accuracy of your personal data and in unlocking new rewards and opportunities for you.</p>
  <p>Keep up the great work and thank you for being part of Nested Club.</p>
  <br />
  <p>Best regards,</p> 
  <p>The Nested Club Team</p>`,
};

/**
 * Generates an email template for notifying a candidate about the approval of employment verification for non kyc candidate.
 *
 * @param {string} userName - The name of the user (candidate) receiving the notification.
 * @param {string} employerName - The name of the employer who confirmed the employment history.
 * @returns An email template object with subject and body for employment verification approval notification.
 */
export const candidateNonKYCExpApproved: templateType = {
  subject: "Employment Verification from {{employerName}}",
  body: `<p>Dear {{userName}},</p>
  <br />
  <p>We're excited to let you know that {{employerName}} has confirmed your employment history on Nested Club! This is an important step in ensuring the accuracy of your personal data and in unlocking new rewards and opportunities for you.</p>
  <p>However, we've noticed that you haven't completed your KYC on the Nested platform yet. Completing your KYC is essential to getting additional benefits and fully utilizing the features of Nested Club.</p>
  <p>Please take a moment to complete your KYC by following the instructions in the "KYC" section of your Nested Club profile.</p>
  <p>Keep up the great work, and thank you for being part of Nested Club.</p>
  <br />
  <p>Best regards,</p> 
  <p>The Nested Club Team</p>`,
};

/**
 * Generates an email template for notifying a candidate about the rejection of employment verification by an employer.
 *
 * @param {string} userName - The name of the user (candidate) receiving the notification.
 * @param {string} employerName - The name of the employer who rejected the employment verification.
 * @returns An email template object with subject and body for employment verification rejection notification.
 */
export const candidateExpRejected: templateType = {
  subject: "Action Required: Verification Rejected by Employer {{employerName}} on Nested Club",
  body: `<p>Dear {{userName}},</p>
    <br />
    <p>We regret to inform you that your employment verification with {{employerName}} has been rejected. This may impact your status in the Nested Club community.</p>
    <p>We recommend that you login to your Nested Club account to review the reason for the rejection and take the necessary next steps. Your account information and the steps you need to take can be found in the "My Profile" section of your Nested Club profile.</p>
    <p>If you have any questions or concerns, please don't hesitate to reach out to our support team. We're here to help you.</p>
    <br />
    <p>Best regards,</p> 
    <p>The Nested Club Team</p>`,
};

/**
 * Generates an email template for notifying users about rejected employment verification for non kyc user.
 *
 * @param {string} userName - The name of the user receiving the email.
 * @param {string} employerName - The name of the employer whose verification was rejected.
 * @returns An email template object with subject and body for the verification rejection notification.
 */
export const candidateNonKYCExpRejected: templateType = {
  subject: "Action Required: Verification Rejected by Employer {{employerName}} on Nested Club",
  body: `<p>Dear {{userName}},</p>
    <br />
    <p>We regret to inform you that your employment verification with {{employerName}} has been rejected. One possible reason for this rejection could be that you have not completed your KYC on Nested Club.</p>
    <p>We recommend that you log in to your Nested Club account to review the reason for the rejection and take the necessary next steps. If the rejection is due to incomplete KYC, please proceed to complete your KYC as soon as possible.</p>
    <p>Your account information and the steps you need to take can be found in the "KYC" section of your Nested Club profile.</p>
    <p>If you have any questions or concerns, please don't hesitate to reach out to our support team. We're here to help you.</p>
    <br />
    <p>Best regards,</p> 
    <p>The Nested Club Team</p>`,
};


/**
 * Generates an email template for notifying a new employer(not onboarded) about an employment verification request for a specific candidate.
 *
 * @param {string} employerName - The name of the employer receiving the notification.
 * @param {string} employeeName - The name of the candidate whose employment is being verified.
 * @param {string} companyName - The name of the company associated with the employment verification.
 * @param {string} country - The country where the employment took place.
 * @param {string} startDate - The start date of the employment.
 * @param {string} endDate - The end date of the employment.
 * @param {string} designation - The job designation of the employee.
 * @param {string} supportEmail - The support email address for Nested Club.
 * @returns An email template object with subject and body for notifying a new employer about an employment verification request.
 */
export const newEmployer: templateType = {
  subject: "Employment Verification for {{employeeName}}",
  body: `<p>Dear {{employerName}},</p>
    <br />
    <p>I hope this email finds you well. I am writing to you on behalf of Nested Club, a community platform that enables individuals to take control of their personal and professional data and leverage it for monetization.</p>
    <p>We recently received a claim from {{employeeName}} stating that they have worked with your esteemed organization, {{companyName}}. As a platform that values authenticity and accuracy, we would appreciate your assistance in verifying this information.</p>
    <p>To facilitate the verification process, we have included the details in the table below:</p>
    <p><table style="width:100%;font-family:arial, sans-serif;border-collapse: collapse;">
    <tr>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Sl No.</th>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Particulars</th>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Details</th>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">1</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Candidate Name</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{employeeName}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">2</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Company Name</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{companyName}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">3</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Country</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{country}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">4</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Start Date</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{startDate}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">5</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">End Date</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{endDate}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">6</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Designation</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{designation}}</td>
    </tr>
    </table></p>
    <p>Kindly note that you must be a registered member of our platform Nested to verify these details.</p>
    <p>By joining Nested Club, you can take advantage of our employer services as well. Our platform offers a convenient solution for finding pre-verified candidates for your job vacancies, simplifying your hiring process, and reducing the time and resources required to sift through resumes and conduct background checks.</p>
    <p>We would like to invite you to join us in this mission. Our team will be in touch to assist you with the process and answer any questions you may have. Meanwhile, if you would like to contact us, please drop an email at {{supportEmail}} and we will get back very soon.</p>
    <p>Thank you for your attention to this matter. We look forward to collaborating with you and fostering a trusted community for professionals.</p>
    <br />
    <p>Best regards,</p> 
    <p>Team Nested</p>`,
};

/**
 * Generates an email template for notifying an employer who has been successfully onboarded to Nested Club about an employment verification request for a specific candidate.
 *
 * @param {string} employerName - The name of the employer receiving the notification.
 * @param {string} employeeName - The name of the candidate whose employment is being verified.
 * @param {string} companyName - The name of the company associated with the employment verification.
 * @param {string} country - The country where the employment took place.
 * @param {string} startDate - The start date of the employment.
 * @param {string} endDate - The end date of the employment.
 * @param {string} designation - The job designation of the employee.
 * @param {string} supportEmail - The support email address for Nested Club.
 * @returns An email template object with subject and body for notifying an employer who has been successfully onboarded to Nested Club about an employment verification request.
 */
export const onBoardedEmployer: templateType = {
  subject: "Employment Verification for {{employeeName}}",
  body: `<p>Dear {{employerName}},</p>
    <br />
    <p>I hope this email finds you in good health and high spirits.</p>
    <p>We have recently been contacted by {{employeeName}}, who claims to have worked with your esteemed organization, {{companyName}}. We kindly request your cooperation in confirming this information by logging into the Nested Club platform.</p>
    <p>To ensure accuracy, please review the following details:</p>
    <p><table style="width:100%;font-family:arial, sans-serif;border-collapse: collapse;">
    <tr>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Sl No.</th>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Particulars</th>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Details</th>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">1</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Candidate Name</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{employeeName}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">2</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Company Name</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{companyName}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">3</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Country</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{country}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">4</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Start Date</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{startDate}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">5</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">End Date</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{endDate}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">6</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Designation</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{designation}}</td>
    </tr>
    </table></p>
    <p>If you come across any discrepancies or have any further inquiries, please feel free to reach out to us at {{supportEmail}}. We will promptly address your concerns and provide assistance as needed.</p>
    <p>Thank you for your attention to this matter. We appreciate your cooperation and prompt response.</p>
    <br />
    <p>Best regards,</p> 
    <p>Team Nested</p>`,
};

const candidateWorkDetailTemplate =
  `<table style="width:100%">
<tr>
  <th>Sl No.</th>
  <th>Particulars</th>
  <th>Details</th>
</tr>
<tr>
  <td>1</td>
  <td>Candidate Name</td>
  <td>{{employeeName}}</td>
</tr>
<tr>
  <td>2</td>
  <td>Company Name</td>
  <td>{{companyName}}</td>
</tr>
<tr>
  <td>3</td>
  <td>Country</td>
  <td>{{country}}</td>
</tr>
<tr>
  <td>4</td>
  <td>Start Date</td>
  <td>{{startDate}}</td>
</tr>
<tr>
  <td>5</td>
  <td>End Date</td>
  <td>{{endDate}}</td>
</tr>
<tr>
  <td>6</td>
  <td>Designation</td>
  <td>{{designation}}</td>
</tr>
</table>`;

/**
 * Generates an email template for a password reset request in Nested Club.
 *
 * @param {string} name - The name of the user receiving the password reset request.
 * @param {string} link - The URL link for resetting the password.
 * @param {string} emailExpiry - The expiration time for the password reset link.
 * @param {string} contactEmail - The contact email address for Nested Club support.
 * @returns An email template object with subject and body for a password reset request in Nested Club.
 */
export const forgotPassword: templateType = {
  subject: "Nested Club Password Reset!",//Request to rest your password
  body: `<p>Dear {{name}},</p>
<br />
<p>We recently received a request to reset the password for your account. If you did not initiate this request, please disregard this email.</p>
<p>To proceed with the password reset, please click on the button below:</p>
<a style= "background-color: #f44336;
  color: white;
  padding: 14px 25px;
  border-radius:15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;" href="{{link}}" target="_blank">Reset Password</a>
<br />
<p>Alternatively, you can copy and paste the following URL into your browser:</p>
<p><a href="{{link}}" target="_blank">{{link}}</a></p>
<p>Please note that this password reset link will expire in the next {{emailExpiry}}, so we recommend completing the process promptly.</p>
<p>If you have any questions or need further assistance, please do not hesitate to reach out to our support team at {{contactEmail}}.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for account verification in Nested Club.
 *
 * @param {string} name - The name of the user receiving the verification email.
 * @param {string} link - The URL link for verifying the email address.
 * @returns An email template object with subject and body for account verification in Nested Club.
 */
export const candidateEmailVerification: templateType = {
  subject: "Account Verification - Action Required",
  body: `<p>Dear {{name}},</p>
<br />
<p>We hope this email finds you well. </p>
<p>Thank you for expressing your interest in joining Nested Club. We would like to verify your account to ensure a secure and seamless experience.</p>
<p>You need to verify your e-mail address by clicking the button below:</p>
<p><a style= "background-color: dodgerblue;
color: white;
padding: 14px 25px;
border-radius:15px;
text-align: center;
text-decoration: none;
display: inline-block;" href="{{link}}" target="_blank">Click here to verify</a></p>
<p>Thank you for your time!</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for confirming the user's email address.
 *
 * @param {string} name - The name of the user receiving the email.
 * @param {string} link - The login link.
 * @param {string} contactEmail - The support team's contact email.
 * @returns An email template object with subject and body for the email confirmation email.
 */
export const candidateEmailConfirmation: templateType = {
  subject: "Your Email Confirmation for Nested Login",
  body: `<p>Dear {{name}},</p>
<br />
<p>Thank you for your email confirmation. You can now access all the features and benefits of our platform.</p>
<p>You can now proceed to <a href="{{link}}" target="_blank">Login</a></p>
<p>We understand the importance of securing your personal information, and we have taken the necessary steps to ensure the confidentiality and integrity of your data. By confirming your email, you have completed an essential part of the registration process, and we appreciate your cooperation.</p>
<p>Should you encounter any difficulties during the login process or have any questions regarding our app's functionality, please don't hesitate to reach out to our support team {{contactEmail}}.</p>
<p>We are here to assist you and provide a seamless user experience.</p>
<p>Thank you for choosing Nested.</p>
<p>Your professional profile monetization journey begins now.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for account verification with a call-to-action button for support user.
 *
 * @param {string} name - The name of the user receiving the email.
 * @param {string} link - The verification link.
 * @returns An email template object with subject and body for the account verification email.
 */
export const supportEmailVerification: templateType = {
  subject: "Account Verification - Action Required",
  body: `<p>Dear {{name}},</p>
<br />
<p>We hope this email finds you well. </p>
<p>Thank you for expressing your interest in joining Team Nested. We would like to verify your account to ensure a secure and seamless experience.</p>
<p>You need to verify your e-mail address by clicking the button below:</p>
<p><a style= "background-color: dodgerblue;
color: white;
padding: 14px 25px;
border-radius:15px;
text-align: center;
text-decoration: none;
display: inline-block;" href="{{link}}" target="_blank">Click here to verify</a></p>
<p>Thank you for your time!</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for welcoming a platform user to Nested Club.
 *
 * @param {string} name - The name of the user receiving the email.
 * @param {string} userEmail - The default ID or email associated with the user's account.
 * @param {string} link - The link for changing the user's password.
 * @param {string} contactEmail - The contact email for the support team.
 * @returns An email template object with subject and body for the welcome email to a platform user.
 */
export const welcomePlatformUser: templateType = {
  subject: "Welcome Platform User",
  body: `<p>Dear {{name}},</p>
<br />
<p>Welcome to Nested Club!</p>
<p>We are excited to have you join us on our mission to empower individuals in controlling and monetizing their professional profiles. </p>
<p><strong>Below are your login credentials:</strong></p>
<p>Default ID: {{userEmail}}</p>
<p>To ensure the security of your account, we highly recommend changing your password. Please click the link provided below to proceed with the password change:</p>
<p><a style= "background-color: dodgerblue;
color: white;
padding: 14px 25px;
border-radius:15px;
text-align: center;
text-decoration: none;
display: inline-block;" href="{{link}}" target="_blank">Click here to change password</a></p>
<p>Join our ever-growing community on 
<a target="_blank" style= "margin-right: 2rem;" href="https://twitter.com/NestedClub"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 16 16" aria-label="Navigate to Twitter" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: -2px;"><path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path></svg>Twitter</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.linkedin.com/company/90594042/"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 1024 1024" aria-label="Navigate to Linkedin" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="stroke: rgb(0, 143, 148); stroke-width: 5; margin-bottom: -2px;"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z"></path></svg>LinkedIn</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.instagram.com/nestedclub/"><svg fill="#008F94" viewBox="0 0 24 24" role="img" aria-label="Navigate to Instagram" focusable="false" style="width: 1rem; height: 1rem;"><path d="m23.09.91c-.61-.61-1.33-.91-2.17-.91h-17.84c-.85 0-1.57.3-2.17.91s-.91 1.33-.91 2.17v17.84c0 .85.3 1.57.91 2.17s1.33.91 2.17.91h17.84c.85 0 1.57-.3 2.17-.91s.91-1.33.91-2.17v-17.84c0-.85-.3-1.57-.91-2.17zm-14.48 7.74c.94-.91 2.08-1.37 3.4-1.37 1.33 0 2.47.46 3.41 1.37s1.41 2.01 1.41 3.3-.47 2.39-1.41 3.3-2.08 1.37-3.41 1.37c-1.32 0-2.46-.46-3.4-1.37s-1.41-2.01-1.41-3.3.47-2.39 1.41-3.3zm12.66 11.63c0 .27-.09.5-.28.68a.92.92 0 0 1 -.67.28h-16.7a.93.93 0 0 1 -.68-.28.92.92 0 0 1 -.27-.68v-10.13h2.2a6.74 6.74 0 0 0 -.31 2.05c0 2 .73 3.71 2.19 5.12s3.21 2.12 5.27 2.12a7.5 7.5 0 0 0 3.75-.97 7.29 7.29 0 0 0 2.72-2.63 6.93 6.93 0 0 0 1-3.63c0-.71-.11-1.39-.31-2.05h2.11v10.12zm0-13.95c0 .3-.11.56-.31.77a1.05 1.05 0 0 1 -.77.31h-2.72c-.3 0-.56-.11-.77-.31a1.05 1.05 0 0 1 -.31-.77v-2.58c0-.29.11-.54.31-.76s.47-.32.77-.32h2.72c.3 0 .56.11.77.32s.31.47.31.76z" fill-rule="evenodd"></path></svg>Instagram</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.facebook.com/people/Nested-Club/100090953042366/"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 16 16" aria-label="Navigate to Facebook" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path></svg>Facebook</a>
<a target="_blank" style= "margin-right: 2rem;" href="https://www.youtube.com/channel/UCIOQ3pgKCThNbTHWiljYJlw"><svg stroke="currentColor" fill="#008F94" stroke-width="0" viewBox="0 0 16 16" aria-label="Navigate to Youtube" focusable="false" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path></svg>YouTube</a>
to stay up-to-date with the latest news and feel free to reach out to us with any questions you may have.</p>
<p>Thank you once again for choosing Nested Club!</p>
<p>Should you require any assistance, please don't hesitate to contact us at {{contactEmail}} or through our social media channels.</p>
<p>We are eager to witness the incredible possibilities that await you and your Nested Club account.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for notifying a user about a successful password change.
 *
 * @param {string} name - The name of the user receiving the email.
 * @param {string} currentDate - The date and time when the password was changed.
 * @param {string} userEmail - The username or email associated with the user's account.
 * @param {string} contactEmail - The contact email for the support team.
 * @returns An email template object with subject and body for the password change notification.
 */
export const changePassword: templateType = {
  subject: "Password Successfully Changed",
  body: `<p>Dear {{name}},</p>
<br />
<p>We are writing to inform you that your password for Nested Club has been successfully changed.</p>
<p>Date and Time of Password Change: {{currentDate}}</p>
<p>Username/Email: {{userEmail}}</p>
<p>If you did not authorize this change or if you believe your account has been compromised, please contact our support team immediately at {{contactEmail}}. We take the security of your account very seriously and will assist you in resolving any issues promptly.</p>
<p>To ensure the ongoing security of your account, we recommend you always</p>
<p>1. Choose a strong and unique password.</p>
<p>2. Regularly update your password</p>
<p>Please keep this information confidential and do not share it with anyone. Our team will never ask you to disclose your password or personal information via email or any other means.</p>
<p>If you have any further questions or require additional assistance, please do not hesitate to reach out to our support team. We are here to help.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for candidate email address verification after an email update.
 *
 * @param {string} name - The name of the candidate receiving the email.
 * @param {string} newEmail - The candidate's updated email address.
 * @param {string} link - The verification link for confirming the updated email address.
 * @param {string} contactEmail - The contact email for the support team.
 * @returns An email template object with subject and body for the email verification after an update.
 */
export const candidateUpdatedEmailVerification: templateType = {
  subject: "Verify Your Updated Email Address",
  body: `<p>Dear {{name}},</p>
<br />
<p>We hope this email finds you well. We wanted to inform you about a recent update made to your email address associated with your account on the Nested platform.</p>
<p>As a part of our commitment to ensuring the security and accuracy of your information, we kindly request you to verify your new email address (newEmail) by clicking on the verification link below:</p>
<p><a style= "background-color: dodgerblue;
color: white;
padding: 14px 25px;
border-radius:15px;
text-align: center;
text-decoration: none;
display: inline-block;" href="{{link}}" target="_blank">Click here to verify</a></p>
<p>Please note that your account will remain active, but certain functionalities may be limited until your new email address is verified. If you did not initiate this change or if you have any concerns, please reach out to our support team at {{contactEmail}}.</p>
<p>Thank you for your cooperation.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for candidate account deletion verification, including a One-Time Password (OTP).
 *
 * @param {string} name - The name of the candidate receiving the email.
 * @param {string} newOtp - The One-Time Password (OTP) for account deletion verification.
 * @param {string} expiryTime - The time duration for which the OTP remains active.
 * @param {string} contactEmail - The contact email for the support team.
 * @returns An email template object with subject and body for the account deletion verification email.
 */
export const candidateDeleteAccountVerification: templateType = {
  subject: "Important: Account Deletion Verification - One-Time Password (OTP) Included - Action Required",
  body: `<p>Dear {{name}},</p>
<br />
<p>We hope this message finds you well. We appreciate your engagement with Nested.</p>
<p>We have received a request to delete your account at Nested Club. If you initiated this request, please follow the instructions below to confirm and proceed with the account deletion process. However, if this was not you and you believe your account's security has been compromised, please ignore this message.</p>
<p>To confirm the account deletion, please use the following One-Time Password (OTP):</p>
<p>One-Time Password (OTP): <strong>{{newOtp}}</strong></p>
<p>Once you've received this email, the OTP will remain active for {{expiryTime}}. Please use the provided OTP to verify and authorize the deletion of your account. Enter it in the designated space on the account deletion verification page.</p>
<p>Please note that this action is irreversible and will result in the permanent deletion of your account, along with all associated data.</p>
<p>If you have any questions or concerns regarding this process, please reach out to our support team immediately at {{contactEmail}}.</p>
<p>Thank you for being a part of Nested Club.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template to welcome an organization to the Nested Club platform.
 *
 * @param {string} companyName - The name of the organization being welcomed.
 * @param {string} onboardingGuideLink - The link to the onboarding guide.
 * @param {string} contactEmail - The contact email for the organization's support.
 * @returns An email template object with subject and body for the welcome message.
 */
export const welcomeOrganization: templateType = {
  subject: "Welcome to Nested Club Platform!",
  body: `<p>Dear {{companyName}},</p>
<br />
<p>Welcome to Nested Club! We're thrilled to have {{companyName}} on board and are looking forward to a fruitful collaboration ahead.</p>
<p>To help you make the most of our platform, we have prepared a comprehensive onboarding guide. This guide will walk you through key features, provide useful tips, and ensure a smooth transition into our ecosystem. You can access the guide <a href="{{onboardingGuideLink}}" target="_blank">here</a>.</p>
<p>At Nested Club, we're dedicated to revolutionizing the way organizations connect with verified talent. Our platform offers a secure, blockchain-based solution for recruitment, ensuring that you connect with qualified professionals swiftly and reliably.</p>
<p>As part of our partnership, we've attached a comprehensive guide that provides detailed insights into the capabilities of Nested Club. This guide will offer a deep dive into our platform, allowing you to understand how we facilitate the interaction between organizations and verified professionals.</p>
<p>Our commitment is to provide an efficient and trusted platform that enhances your hiring experience. Should you have any questions or require assistance navigating the platform, our dedicated support team is here to help. You can reach them at <a href="mailto:{{contactEmail}}">{{contactEmail}}</a></p>
<p>Once again, welcome to Nested Club! We're excited to embark on this journey with {{companyName}} and make strides together in finding the right talent for your team.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for acknowledging the receipt of a job application to employer from a candidate.
 *
 * @param {string} employerName - The name of the employer to whom the acknowledgment is addressed.
 * @param {string} jobTitle - The title of the job position for which the candidate applied.
 * @param {string} candidateName - The name of the candidate who submitted the application.
 * @param {string} supportEmail - The email address for contacting support.
 * @returns An email template object with subject and body for the application acknowledgment.
 */
export const candidateJobApplicationAcknowledgment: templateType = {
  subject: "Application Acknowledgment - {{jobTitle}} - {{candidateName}}",
  body: `<p>Dear {{employerName}},</p>
<br />
<p>I trust this message finds you well.</p>
<p>I wanted to inform you that we have received an application for the {{jobTitle}} job at our organization from {{candidateName}}.</p>
<p>To provide you with more details on {{candidateName}}'s application, I would like to invite you to view the candidate's information and details by logging into our Nested Club platform. This will allow you to access {{candidateName}}'s basic info, cover letter, and any additional documents submitted.</p>
<p>We appreciate your collaboration in evaluating {{candidateName}}'s qualifications and suitability for the {{jobTitle}} position.</p>
<p>If you come across any discrepancies or have any further inquiries, please feel free to reach out to us at {{supportEmail}}. We will promptly address your concerns and provide assistance as needed.</p>
<p>Thank you for your attention to this matter. We value your time and dedication to the hiring process.</p>
<br />
<p>Best regards,</p>
<p>Team Nested</p>`,
};

/**
 * Generates an email template for sending a verification request to a candidate regarding their employment details.
 *
 * @param {string} employeeName - The name of the candidate.
 * @param {string} country - The country where the candidate worked.
 * @param {string} startDate - The start date of the candidate's employment.
 * @param {string} endDate - The end date of the candidate's employment.
 * @param {string} designation - The job title or designation of the candidate.
 * @param {string} employerName - The name of the employer who needs to verify the details.
 * @param {string} supportEmail - The email address for contacting support.
 * @returns An email template object with subject and body for the verification request.
 */
export const candidateEmploymentVerification: templateType = {
  subject: "Verification Request for Your Employment Details at {{employerName}}",
  body: `<p>Dear {{employeeName}},</p>
    <br />
    <p>I hope this message finds you well. We are reaching out to you from Nested Club, a community platform dedicated to empowering individuals in managing their personal and professional data.</p>
    <p>We have recently received a claim from you stating that you have worked with the esteemed organization {{employerName}}. As part of our commitment to accuracy and authenticity, we are in the process of verifying this information.</p>
    <p>To facilitate the verification process, we have outlined the details in the table below:</p>
    <p><table style="width:100%;font-family:arial, sans-serif;border-collapse: collapse;">
    <tr>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Sl No.</th>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Particulars</th>
      <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Details</th>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">1</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Candidate Name</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{employeeName}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">2</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Company Name</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{employerName}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">3</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Country</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{country}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">4</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Start Date</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{startDate}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">5</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">End Date</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{endDate}}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">6</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Designation</td>
      <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">{{designation}}</td>
    </tr>
    </table></p>
    <p>If you have any additional information or documentation that could aid in the verification process, please feel free to provide it.</p>
    <p>Kindly note that for the verification to be complete, the employer, {{employerName}}, must be a registered member of our platform, Nested. We encourage you to reach out to your former employer and request their participation in this process.</p>
    <p>Additionally, Nested Club offers a range of services for candidates, including the ability to showcase your verified professional background to potential employers. If you are not yet a member of Nested, we invite you to join our platform.</p>
    <p>Should you have any questions or concerns, please do not hesitate to contact us at {{supportEmail}}. Our team is here to assist you throughout the process.</p>
    <p>Thank you for your cooperation in this matter. We look forward to facilitating a seamless verification process and assisting you in leveraging your professional data effectively.</p>
    <br />
    <p>Best regards,</p>
    <p>Team Nested</p>`,
};
