import { Box, Flex, GridItem } from "@chakra-ui/react";
import { FC, ReactElement } from "react";
import Footer from "./footer";
import Header from "./header";
import LeftPanel from "../../components/buy-operations/LeftPanel";
import Container from "../../components/shared/Container";
import Stack from "../../components/shared/Stack";
import HeaderStrip from "./header-strip";

interface LayoutProps {
  routeType?:
    | "public"
    | "company"
    | "employee"
    | "admin"
    | "platform-support"
    | "platform-manager"
    | "operation";
  children?: any;
}

const Layout: FC<LayoutProps> = (props) => {
  const { routeType = "public" } = props;
  return (
    <Flex flexDir="column" bg="#f5f5f5" pos="relative" minH="100vh">
      <Box
        position="absolute"
        display={{
          base: "none",
          lg: "block",
        }}
        bg="#264252"
        h="312px"
        top="0"
        w="full"
        left="0"
        bgImage='url("/images/header-bg.png")'
        bgRepeat="space no-repeat"
        bgPos="bottom"
      />
      <Header routeType={routeType} />
      <HeaderStrip />
      <Box as="main" pos="relative" flex={1}>
        {props.children}
      </Box>
      <Footer />
    </Flex>
  );
};

const companyLayout = function getLayout(page: ReactElement) {
  return <Layout routeType="company">{page}</Layout>;
};

const employeeLayout = function getLayout(page: ReactElement) {
  return <Layout routeType="employee">{page}</Layout>;
};

const publicLayout = function getLayout(page: ReactElement) {
  return <Layout routeType="public">{page}</Layout>;
};

const adminLayout = function getLayout(page: ReactElement) {
  return <Layout routeType="admin">{page}</Layout>;
};

const platformManagerLayout = function getLayout(page: ReactElement) {
  return <Layout routeType="platform-manager">{page}</Layout>;
};

const platformLayout = function getLayout(page: ReactElement) {
  return <Layout routeType="platform-support">{page}</Layout>;
};

const operationLayout = function getLayout(page: ReactElement) {
  return (
    <Layout routeType="employee">
      <Container>
        <Stack my={[2, 12]} align="stretch" spacing={3}>
          <LeftPanel />
          {page}
        </Stack>
      </Container>
    </Layout>
  );
};

export {
  publicLayout,
  companyLayout,
  employeeLayout,
  adminLayout,
  platformManagerLayout,
  platformLayout,
  operationLayout,
};
