export const useStyles = {
  errorColor: "red.500",
  exactColor: "gray.300"
};

export const imagestyle = {
  normal: {
    height: 123,
    width: 123,
    borderRadius: 60
  },
  default: {
    height: 123,
    borderRadius: 60,
  },
  small: {
    height: 68,
    width: 68,
    borderRadius: 34
  },
  blur: {
    height: 68,
    width: 68,
    borderRadius: 34,
    filter: "blur(3px)"
  }
}