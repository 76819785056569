import { FC } from "react";
import NextLink from "next/link";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";

const Link: FC<LinkProps> = (props) => {
  const { href, ...rest } = props;
  return (
    <NextLink
      href={{
        pathname: href,
      }}
      passHref
    >
      <ChakraLink _hover={{}} {...rest} />
    </NextLink>
  );
};

export default Link;
