import { chakra, VStack } from "@chakra-ui/react";

const Card = chakra(VStack, {
  baseStyle: {
    bg: "white",
    rounded: {
      base: "none",
      lg: "2xl",
    },
    boxShadow: {
      base: "none",
      lg: "xl",
    },
    alignItems: {
      sm: "center",
      md: "stretch",
    },
    minH: {
      base: "auto",
      lg: "600px",
    },
  },
});

export default Card;
